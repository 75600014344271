import React, { useState } from 'react';
import { Typography, TextField, Button, CircularProgress, Autocomplete,
    Card, CardContent, CardHeader, IconButton, List, ListItem, Grid,
    Tooltip } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { useAppContext } from '../contexts/AppContext';
import { useAuth } from "../contexts/AuthContext";

import Markdown from 'markdown-to-jsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import config from '../config';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ProjectStrategyRAG = ({projectData}) => {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [editable, setEditable] = useState(false);

    const { /* getSearchResultsInDocsStateless, */ getSearchResultsInDocsStatelessV2, queryGenerativeModel } = useAppContext();
    const { currentTenant } = useAuth();

    // Example questions for the autocomplete
    const questions = [
        "Wie ist die qualitative Bewertung der Projektabwicklung und welche Handlungen müssen getätigt werden um die Qualitäten ggf. zu erhöhen?",
        "Wie können wir unsere Projektabwicklung verbessern?",
        "Was sind die strategischen Ziele für das nächste Quartal?",
        "Gibt es Risiken bei unseren laufenden Projekten?",
        "Wie wollen wir unseren Marktanteil ausbauen?",
        "Welche Verbesserungen können wir vornehmen, um die Effizienz zu steigern?"
    ];

    const handleAddQuestion = async () => {
        /* const project_path = `/${currentTenant}/${projectData.name}/`; */
        const strategy_directory = `/${currentTenant}/${projectData.name}/Strategie`;
        const protocol_directory = `/${currentTenant}/${projectData.name}/Protokolle`;
        const references_directory = `/${currentTenant}/Vorgaben Vorlagen und Richtlinien`;

        if (inputValue || selectedQuestions.length > 0) {
            setIsLoading(true);
            const updatedQuestions = [...selectedQuestions, ...(inputValue ? [inputValue] : [])];
            setSelectedQuestions(updatedQuestions);
            
            const searchString = updatedQuestions.join(', ');

            let strategy_matches = [];
            try {
                strategy_matches = await getSearchResultsInDocsStatelessV2(
                    config.envs[config.active_env].domain + config.api_path,
                    5, // max nr. of documents
                    3, // max inner hits per document
                    /* project_path + ',' +  */strategy_directory,
                    searchString/* ,
                    strategy_directory */
                );
            } catch (error) {
                console.error('Failed to fetch matches:', error);
                // Handle the error appropriately, show an error message to the user or perform some other action
            }

            let protocol_matches = [];
            try {
                protocol_matches = await getSearchResultsInDocsStatelessV2(
                    config.envs[config.active_env].domain + config.api_path,
                    5, // max nr. of documents
                    3, // max inner hits per document
                    /* project_path + ',' +  */protocol_directory,
                    searchString
                );
            } catch (error) {
                console.error('Failed to fetch matches:', error);
                // Handle the error appropriately, show an error message to the user or perform some other action
            }

            let references_matches = [];
            try {
                references_matches = await getSearchResultsInDocsStatelessV2(
                    config.envs[config.active_env].domain + config.api_path,
                    5, // max nr. of documents
                    3, // max inner hits per document
                    /* project_path + ',' +  */references_directory,
                    searchString/* ,
                    strategy_directory */
                );
            } catch (error) {
                console.error('Failed to fetch matches:', error);
                // Handle the error appropriately, show an error message to the user or perform some other action
            }

            const { distribution, ...adaptedProjectData } = projectData;

            if (strategy_matches.length === 0 && protocol_matches.length === 0 && references_matches.length === 0) {
                // Handle no results
                setResponse(`Keine Ergebnisse gefunden für: "${searchString}"`);
            } else {
                const strategyMatchesString = strategy_matches.map(match => 
                    match.text_sections.map(section => section.text).join(', ')
                ).join(', ');
                const protocolString = protocol_matches.map(match => 
                    match.text_sections.map(section => section.text).join(', ')
                ).join(', ');
                const referencesString = references_matches.map(match => 
                    match.text_sections.map(section => section.text).join(', ')
                ).join(', ');
                const messages = [
                    { role: 'system', content: 
                        "You are a Question-answering assistant, specialized in the construction and real-estate industry. You will be provided with user questions (USER_QUERY) regarding strategic project decisions. You will be provided with an array of objects (Strategie) containing an attribute 'text'. This information is the most relevant strategic data. Another array with the same structure will be provided with meeting notes describing the project status (Protokolle). Another array with the same structure will be provided with reference documents like laws and regulations (Vorgaben Vorlagen und Richtlinien). Finally, you will be provided with general project information (Projektdetails). Use all this information as your context to answer the user questions (USER_QUERY). Include the names of the files used to provide the answer. Never return the complete content of a document. Provide an answer in German." },
                    { role: 'assistant', content: 'Strategie: ' + strategyMatchesString },
                    { role: 'assistant', content: 'Protokolle: ' + protocolString },
                    { role: 'assistant', content: 'Vorgaben Vorlagen und Richtlinien: ' + referencesString },
                    { role: 'assistant', content: 'Projektdetails: ' + JSON.stringify(adaptedProjectData) },
                    { role: 'user', content: 'USER_QUERY: ' + searchString }
                ];
                const queryResponse = await queryGenerativeModel(messages);
                if (!queryResponse) queryResponse = 'Ergebnisse konnten nicht generiert werden. Bitte versuchen Sie es nochmals.'
                setResponse(queryResponse);
            }

            setIsLoading(false);
            setInputValue(''); // Clear input field after adding
        }
    };

    const handleSaveEdits = () => {
        // Simply toggle editable state off to make it read-only again
        setEditable(false);
    };

    const clearInputAndQuestions = () => {
        setSelectedQuestions([]);
        setInputValue('');
    };

    const handleSaveAsPDF = () => {
        const docDefinition = {
            content: [
                { text: 'Fragen an meine Organisation', style: 'header' },
                {
                    ul: selectedQuestions.map((question, index) => ({
                        text: `${index + 1}. ${question}`,
                        style: 'question'
                    }))
                },
                { text: 'Antwort:', style: 'subheader', margin: [0, 10, 0, 10] },
                { text: response, style: 'response', margin: [0, 0, 0, 10] }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 15,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                question: {
                    margin: [0, 5, 0, 5]
                },
                response: {
                    fontSize: 12
                }
            }
        };

        pdfMake.createPdf(docDefinition).download('response.pdf');
    };

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <CardHeader 
                title={
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }}>
                        <AutoAwesomeIcon color="secondary" sx={{ verticalAlign: 'middle', marginRight: 1, marginBottom:2 }} />
                        <Tooltip title={config.ui_messages.ai_disclaimer}>
                            <IconButton sx={{ padding: 0, marginRight: 2 }}> 
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        Fragen an meine Organisation stellen
                    </Typography>
                } 
                sx={{ textAlign: 'left', paddingBottom: 0 }}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography variant="subtitle2" align='left' sx={{ fontSize: '0.75rem', alignItems: 'flex-start' }}>
                        Eine Projektabwicklungsstrategie ist ein umfassender Plan, der festlegt, wie ein Projekt von Anfang bis Ende durchgeführt wird. Sie beinhaltet die Definition von Zielen, die Festlegung von Verfahren und die Zuteilung von Ressourcen, um die Projektziele zu erreichen. Diese Strategie soll sicherstellen, dass das Projekt effizient, innerhalb des vorgegebenen Zeitrahmens und Budgets abgewickelt wird. Zu den Hauptfragen und Feststellungen, die sich aus einer Projektabwicklungsstrategie ergeben können, gehören:
                        </Typography>
                        <List dense sx={{ fontSize: '0.75rem', alignItems: 'flex-start' }}>
                            <ListItem sx={{ fontSize: '0.75rem', alignItems: 'flex-start' }}><b>Aufwandswerte</b>: Schätzung des Zeit- und Ressourcenaufwands, der zur Erreichung der Projektziele benötigt wird.</ListItem>
                            <ListItem sx={{ fontSize: '0.75rem', alignItems: 'flex-start' }}><b>Koordinationsunterstützung</b>: Definition der Mechanismen zur Unterstützung der Koordination zwischen verschiedenen Teams oder Abteilungen, um sicherzustellen, dass alle aufeinander abgestimmt sind und effizient arbeiten.</ListItem>
                            <ListItem sx={{ fontSize: '0.75rem', alignItems: 'flex-start' }}><b>Umsetzung der Projektabwicklungsstrategie</b>: Überwachung und Anpassung der Strategie basierend auf der Projektfortschritt und etwaigen Herausforderungen.</ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            options={questions}
                            freeSolo
                            value={selectedQuestions}
                            onChange={(event, newValue) => setSelectedQuestions(newValue)}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Wählen oder eine Frage eingeben" 
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoading ? <CircularProgress size={24} /> : null}
                                                {selectedQuestions.length > 0 || inputValue ? (
                                                    <IconButton onClick={clearInputAndQuestions}>
                                                        {/* <ClearIcon /> */}
                                                    </IconButton>
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                        {!editable && (
                            <Button variant="contained" color="primary" onClick={handleAddQuestion} disabled={isLoading} sx={{ marginRight: 1 }}>
                                Frage stellen
                            </Button>
                        )}
                        {editable && (
                            <Button
                                variant="outlined"
                                onClick={handleSaveEdits}
                                sx={{ marginRight: 1  }}
                            >
                                Anpassungen Speichern
                            </Button>
                        )}
                        {!editable && response && !isLoading && (
                            <Button variant="outlined" onClick={() => setEditable(true)} sx={{ marginRight: 1 }}>
                                Antwort Anpassen
                            </Button>
                        )}
                        {!editable && response && !isLoading && (
                            <Button variant="outlined" onClick={handleSaveAsPDF} sx={{ marginLeft: 1 }}>
                                Als PDF Speichern
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {!isLoading && (
                            editable ? (
                                <TextField
                                    align='left'
                                    fullWidth
                                    multiline
                                    rows={20}
                                    variant="outlined"
                                    value={response}
                                    onChange={(e) => setResponse(e.target.value)}
                                    InputProps={{ readOnly: !editable }}
                                />
                            ) : (
                                <Markdown align='left'>{response}</Markdown>
                            )
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProjectStrategyRAG;
