import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Typography } from '@mui/material';

function ReportMethodDropdown({ selectedMethod, setSelectedMethod, project }) {
  const handleChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const options = [
    {
      value: "insights",
      title: "Strategische Einblicke",
      description: "Individuelle strategische Analyse von Interessengebieten.",
      enabled: false
    },
    {
      value: "Bewertung",
      title: "Acceptance Criteria - Mehtode",
      description: "Benutzerdefinierte Bewertung, basierend auf unserer wachsenden Bibliothek von Akzeptanzkriterien.",
      enabled: true
    },
    {
      value: "smart",
      title: "SMART - Methode",
      description: "EEine Bewertugnsmethode hinsichtlich der Qualitäten von gestellten Szenarien im Projekt.",
      enabled: true
    },
    {
      value: "swot",
      title: "SWOT - Methode",
      description: "Bewertugnsmethode hinsichtlich thematischer Stärken, Schwächen, Oportunitäteren und Risiken.",
      enabled: false
    },
    {
      value: "earned_value",
      title: "Earned Value - Methode",
      description: "Validierung von durchschnittlichen Verlaufzeiten von Aufgaben und validierten Resultaten.",
      enabled: false
    },
    {
      value: "quality_gate",
      title: "Quality Gate - Methode",
      description: "Extraktionsprozess für Stammdaten zwecks der eindeutigen Bauproduktklassifizierung.",
      enabled: false
    },
    {
      value: "bim",
      title: "BIM - Methode",
      description: "Validierung und Auswertung von Dokumenten in sIFC Schema",
      enabled: false
    }
  ];

  if (!project) {
    console.log('Project is not Set for Report.');
    return <Typography variant="body">Bitte wählen Sie ein Projekt aus, um ein Bericht Art wählen zu können.</Typography>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Method</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedMethod}
        label="Method"
        onChange={handleChange}
      >
        {options.map((option) => (
          
            <MenuItem value={option.value} disabled={!option.enabled}>
                <Tooltip key={option.value} title={option.description} placement="right" arrow>
                    {option.title}
                </Tooltip>
            </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ReportMethodDropdown;
