import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import { Chip, Box, Button, Toolbar, InputBase, MenuItem, Grid, InputLabel, Select, TextField , Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';

import { useAppContext } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';

/* import NumberInputBasic from './NumberInputBasic'; */
import BasicTabs from './NavigationTabs';

import config from '../config';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80ch',
    },
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  padding: '0px !important',
  margin: '0px !important',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  '@media all': {
    minHeight: 70,
  },
}));

export default function PrimarySearchAppBar({project, onProjectSelection}) {
  const { currentTenant } = useAuth();
  const { setQuery, activeTab, documentSearchV2, userProjects } = useAppContext();

  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchHistory, setSearchHistory] = useState([]);

  /* const [project, setProject] = useState('all'); */
/*   const [loading, setLoading] = useState(false); */
/*   let [searchParams, setSearchParams] = useSearchParams(); */

  const [top_k, setTopK] = React.useState(config.ui_settings.search_doc_top_k);
  const [inner_hits_size, setInnerHitsSize] = React.useState(config.ui_settings.search_doc_inner_hits);

  const handleTopKChange = (event) => {
    setTopK(event.target.value);
  };

  const handleInnerHitsSizeChange = (event) => {
    setInnerHitsSize(event.target.value);
  };

  /* const handleProjectSelection = (event) => {
    setProject(event.target.value);
  }; */

  const runSearchQuery = () => {
    if (searchQuery) {
      setQuery(searchQuery);
      documentSearchV2(project, searchQuery,  top_k, inner_hits_size/* , "/" */);
      updateSearchHistory(searchQuery);
    }
  };

  const updateSearchHistory = (newQuery) => {
    const combinedQuestions = Object.values(config.search_questions).flat();
    if (newQuery && !searchHistory.includes(newQuery) && !combinedQuestions.includes(newQuery)) {
      const updatedHistory = [newQuery, ...searchHistory].slice(0, 3); // limit to 5 entries
      sessionStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
      setSearchHistory(updatedHistory);
    }
  };

  /* const loadQueryFromURLParams = () => {
    if (searchParams) {
      const newQuery = searchParams.get('query');
      if (newQuery) {
        setSearchQuery(newQuery);
        setQuery(newQuery);
        documentSearch(project, newQuery, "/");
        searchParams.delete('query');
        if (searchParams.has('tab_id')) {
          searchParams.delete('tab_id');
        }
        setSearchParams(searchParams);
      }
    }
  }; */

  useEffect(() => {
    /* loadQueryFromURLParams(); */
    const loadedHistory = JSON.parse(sessionStorage.getItem('searchHistory')) || [];
    setSearchHistory(loadedHistory);
  }, []);

  const removeAfterDash = (str) => {
    return str.split('-')[0];
  };

  const tenant = currentTenant ? removeAfterDash(currentTenant.toLowerCase()) : '';

  const getQuestions = () => {
    const generalQuestions = config.search_questions['general'] || [];
    const tenantQuestions = config.search_questions[tenant] || [];
    return [...generalQuestions, ...tenantQuestions];
  };

  const getOptions = () => {
    const questions = getQuestions();
    /* const loadedHistory = JSON.parse(sessionStorage.getItem('searchHistory')) || []; */

    return [...searchHistory, ...questions]
  }

  return (
    <Box id='root_box_appbar' sx={{ flexGrow: 1 }}>
      <Box id='appbar_box'>
        {/* <BasicTabs /> */}
        <AppBar position="static" sx={{ backgroundColor: '#ccc' }}>
          <StyledToolbar id='styled_toolbar'>

            <Grid id='main_toolbar_grid' container direction={'column'} sx={{ flexGrow: 1, alignSelf: 'flex-end' }}>
              <Box id='appbar_top_row' sx={{
                width: '100%',
                padding: '0px !important',
                margin: '0px !important',
                padding: '0',
                flexGrow: 1, alignSelf: 'flex-end',
              }}>

                <Grid id='appbar_top_grid' container sx={{ padding: '0px !important', width: '100%', flexGrow: 1, alignSelf: 'flex-end' }}>
                  <Grid id='appbar_top_griditem_center' container item xs={12} sx={{ padding: 1 }}>

                    <Grid id='appbar_top_gridsubitem_main' item xs={10} sx={{ paddingLeft: '0px !important', flexGrow: 1, alignSelf: 'flex-end' }}>
                    <Autocomplete
                      freeSolo
                      id="search-autocomplete"
                      disableClearable
                      options={getOptions()}
                      inputValue={searchQuery}
                      onInputChange={(event, newInputValue) => {
                        setSearchQuery(newInputValue);
                      }}
                      renderOption={(props, option) => {
                        const isGeneralQuestion = config.search_questions['general'].includes(option);
                        const isInSearchHistory = searchHistory.includes(option);
                        return (
                          <li {...props}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',  }}>
                              <span>{option}</span>
                              {isGeneralQuestion ? (
                                <Chip label="Standard Frage" sx={{ marginLeft: 1 }} />
                              ) : (
                                isInSearchHistory && <Chip label="Suchanfrage" variant='outlined' sx={{ marginLeft: 1, backgroundColor: isInSearchHistory ? '#EEFEFF' : 'transparent' }} />
                              )}
                            </Box>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <Search sx={{ paddingLeft: '0px !important', marginLeft: '0px !important', color: 'black', backgroundColor: 'white' }}>
                          <SearchIconWrapper>
                            <SearchIcon />
                          </SearchIconWrapper>
                          <StyledInputBase sx={{ flexGrow: 1, maxWidth: 'none', width: '100% !important', margin: '0 !important', padding: '0 !important' }}
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            placeholder="Suchen nach..."
                          />
                        </Search>
                      )}
                    />

                    </Grid>

                    <Grid container item xs={2} sx={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                      <Button variant="contained"
                        onClick={runSearchQuery}
                        sx={{
                          flexGrow: 1,
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid black',
                          '&:hover': {
                            background: "black",
                            color: 'white'
                          },
                        }}>
                        Suchen
                      </Button>
                    </Grid>

                  </Grid>
                </Grid>

              </Box>

              <Box id='appbar_bottom_row' sx={{
                width: '100%', paddingTop: '0px', paddingBottom: '0px', margin: 'auto',
                flexGrow: 1, alignSelf: 'flex-end',
              }}>

                <Grid id='appbar_bottom_grid' container sx={{ paddingLeft: '0px', flexGrow: 1, alignSelf: 'flex-end' }}>
                  <Grid id='appbar_bottom_griditem_center' item xs={12} sx={{ padding: 1, paddingTop: 0, flexGrow: 1, alignSelf: 'flex-end' }}>
                    <Grid container direction='row' sx={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                      
                      <Grid item sx={{ padding: '8px',  }}>
                        <InputLabel id="project-label" sx={{ textAlign: 'left', paddingBottom: '2px', fontSize: '0.7rem', lineHeight: '1.4375em' }}>
                          {activeTab === 0 ? "Projekte" : "Themen"}
                        </InputLabel>
                        <Select
                          labelId="project-label"
                          id="project"
                          value={project}
                          onChange={onProjectSelection}
                          sx={{ backgroundColor: 'white', height: '40px', width: 'auto' }}
                        >
                          {activeTab === 0 ? (
                            userProjects.map((projectItem) => (
                              <MenuItem key={projectItem} value={projectItem}>{projectItem}</MenuItem>
                            ))
                          ) : (
                            config.topicMenuItems.map((item, index) => (
                              <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))
                          )}
                          <MenuItem value={'all'}>{activeTab === 0 ? "Alle Projekte" : "Alle Themen"}</MenuItem>
                        </Select>
                      </Grid>

                      <Grid item sx={{ flexGrow: 0, padding: '8px',  display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <InputLabel id="res_count_label" sx={{ textAlign: 'left', paddingBottom: '2px', fontSize: '0.7rem', lineHeight: '1.4375em'}}>
                          Dokumente
                        </InputLabel>
                        <TextField
                          id="max-documents-count"
                          type="number"
                          InputProps={{ inputProps: { min: 5, max: 100, step: 5 } }}
                          variant="outlined"
                          size="small"
                          value={top_k}
                          onChange={handleTopKChange}
                          sx={{backgroundColor: 'white', borderRadius: '4px'}}
                        />
                      </Grid>

                      <Grid item sx={{ flexGrow: 0, padding: '8px',  display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <InputLabel id="res_count_label" sx={{ textAlign: 'left', paddingBottom: '2px', fontSize: '0.7rem', lineHeight: '1.4375em'}}>
                          Ergebnisse im Dokument
                        </InputLabel>
                        <TextField
                          id="max-result-count"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 100, step: 1 } }}
                          variant="outlined"
                          size="small"
                          value={inner_hits_size}
                          onChange={handleInnerHitsSizeChange}
                          sx={{backgroundColor: 'white', borderRadius: '4px'}}
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>

              </Box>

            </Grid>
          </StyledToolbar>
        </AppBar>
      </Box>
    </Box>
  );
}
