import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const renderer = new marked.Renderer();

// Override the paragraph function to handle cases where `text` might not be a string
/* renderer.paragraph = (text) => {
  // Ensure `text` is a string before performing string operations
  if (typeof text === 'string') {
    if (text.startsWith('<div>') && text.endsWith('</div>')) {
      return text; // Return the text as is, no <p> tag
    }
  }
  return `<p>${text}</p>`; // Safely return the paragraph wrapped text
}; */

marked.setOptions({
  renderer,
  breaks: true, // Converts line breaks into <br>
  gfm: true    // Enables GitHub Flavored Markdown
});

const MarkdownRenderer = ({ markdown }) => {
  const getMarkdownText = () => {
    let rawMarkup = marked(markdown);
    let sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: sanitizedMarkup };
  };

  return <div dangerouslySetInnerHTML={getMarkdownText()} />;
};

export default MarkdownRenderer;
