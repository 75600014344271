import React, { useState } from 'react';
import { 
  Menu, MenuItem, Typography, IconButton} from '@mui/material';
import { styled } from '@mui/system';

import MoreVertIcon from '@mui/icons-material/MoreVert';


const StyledMenuItem = styled(MenuItem)(({ theme, color }) => ({
    display: 'flex',
    alignItems: 'center',
    color: color || 'inherit', // Apply color if provided
    '& .icon': {
      marginRight: theme.spacing(1),
      color: color || 'inherit', // Apply color to the icon as well
    },
}));
  
const CustomContextMenu = ({ anchorEl, onClose, onMenuItemClick, options }) => {
    const handleMenuItemClick = (option) => {
        onMenuItemClick(option);
        onClose();
    };

    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        {options.map((option) => (
            <StyledMenuItem
            key={option.label}
            onClick={() => handleMenuItemClick(option)}
            style={option.color ? { color: option.color } : {}} // Apply the color style conditionally
            >
            <div className="icon" style={option.color ? { color: option.color } : {}}>{option.icon}</div> {/* Color the icon as well */}
            <Typography variant="inherit" noWrap>
                {option.label}
            </Typography>
            </StyledMenuItem>
        ))}
        </Menu>
    );
};
  
export default function ReportGridRowContextMenu ({ onMenuItemClick, options }) {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleContextMenuClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };
  
    const handleContextMenuClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <>
        <IconButton onClick={handleContextMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <CustomContextMenu
          anchorEl={anchorEl}
          options={options}
          onClose={handleContextMenuClose}
          onMenuItemClick={onMenuItemClick}
        />
      </>
    );
};
