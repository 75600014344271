
import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function TimeStamp(props) {
    const CreateTimeStamp = () => {
        if(props.count > 0){
            return(
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{paddingLeft:4}}>{props.timeStamp}</Typography>
                </Box>
            );
        }
    };

    return (
        <CreateTimeStamp/>
    );
}