// Result List
import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Snackbar from '../components/SnackBar';
import { Button } from '@mui/material';
import { 
    Typography,
    Box,
    List, 
    ListItemButton, 
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TimeStamp from '../components/TimeStamp';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LinearProgressIndeterminate from '../components/LinearProgressIndeterminate';

import { styled } from '@mui/material/styles';

import DrawerHeader from '../components/NavigationHeader';
import SearchResultQA from '../components/SearchResultQA';
import AppBar from '../components/SearchQAAppbar';
import { useAppContext } from '../contexts/AppContext';
import config from '../config';
const drawerWidth = config.ui_settings.drawer_width;



export default function QAPage() {
    const { qa_history, drawerOpen, qaQueryLoading, qaDeleteLoading, qaUploadLoading } = useAppContext();  

    const [ts_docsResultsLoading, setTSDocsResultsLoadingStatus] = React.useState(false);
    const [open_docs, setOpen_docs] = React.useState(true);

    const handleClick_Docs = () => {
        setOpen_docs(!open_docs);
    };

    const save_history_txt = (content) => {
        let qa_history_formatted = content.map((e, i) => ("Frage " + parseInt(i+1) + ": " + e.question + ", Antwort " + parseInt(i+1) + ": " + e.answer.response));
        qa_history_formatted = qa_history_formatted.join("\n");
        let file = "data:text/plain;charset=utf-8," + encodeURIComponent(qa_history_formatted);
        return file;
    };

    const save_history_json = (content) => {
        var file = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(content));
        return file;
    };

    const QAList = () => {
        if(Object.keys(qa_history).length > 0) {
            return(
                <Box>
                    <ListItemButton onClick={handleClick_Docs}>
                        <ListItemIcon>
                            <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                <Typography variant="h5" sx={{fontWeight:'bold', padding:2}}>
                                    Szenarioergebnisse ({Object.keys(qa_history).length}): 
                                </Typography>
                                <TimeStamp count={Object.keys(qa_history).length} timeStamp={ts_docsResultsLoading}/>
                            </div>  
                        } />
                        <Box sx={{padding:2 }}>
                            <a
                                href={save_history_txt(qa_history)}
                                download="Szenario.txt"
                                target="_blank"
                                rel="noreferrer"
                            >   
                                <Button><div sx={{margin:1, padding:1}}>TXT</div><SaveAltIcon sx={{padding:0.25}}/></Button>  
                            </a>
                            <a
                                href={save_history_json(qa_history)}
                                download="Szenario.json"
                                target="_blank"
                                rel="noreferrer"
                            >   
                                <Button><div sx={{margin:1, padding:1}}>JSON</div><SaveAltIcon sx={{padding:0.25}}/></Button>  
                            </a>
                        </Box>
                        {open_docs ? <ExpandLess /> : <ExpandMore />}

                    </ListItemButton>
                    <Collapse in={open_docs} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {Object.entries(qa_history).map((result, index) => (
                                <SearchResultQA key={index} index={index} question={result[1].question} answer={result[1].answer}/>
                            ))}
                        </List>
                    </Collapse>
                </Box>
            )          
        }
    };

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
        ({ theme, drawerOpen }) => ({
          flexGrow: 1,
          padding: theme.spacing(0),
          marginLeft: `calc(${theme.spacing(8)} + 1px)`,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(drawerOpen && {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `calc(${drawerWidth}px)`,
          }),
        }),
      );

    return (
        <Main drawerOpen={drawerOpen}>
            <DrawerHeader />
            <AppBar/>
            <LinearProgressIndeterminate isLoading={qaQueryLoading}/>
            <LinearProgressIndeterminate isLoading={qaUploadLoading}/>
            <LinearProgressIndeterminate isLoading={qaDeleteLoading}/>
            <QAList/>
            <Divider/>
            <Snackbar/>
      </Main>
    );
}