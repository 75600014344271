// src/pages/MobileMessagePage.jsx
import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

// Styled components for the message page
const BackgroundContainer = styled('div')({
  width: '100vw',
  height: '100vh',
  backgroundImage: 'url("/background.webp")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const MessageBox = styled(Box)({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '20px',
  borderRadius: '10px',
  textAlign: 'center',
  maxWidth: '90%',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
});

const MobileMessagePage = () => (
  <BackgroundContainer>
    <MessageBox>
      <Typography variant="h6" gutterBottom>
        Diese App ist derzeit nur für Desktop-Geräte optimiert.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Für weitere Informationen besuchen Sie bitte unsere Website.
      </Typography>
      <Link href="https://nukleus.ch" target="_blank" rel="noopener noreferrer">
        https://nukleus.ch
      </Link>
    </MessageBox>
  </BackgroundContainer>
);

export default MobileMessagePage;
