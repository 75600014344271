// Result List
import React, { useEffect, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { 
    Typography,
    Box,
    List, 
    ListItemButton, 
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse
} from '@mui/material';
import TimeStamp from '../components/TimeStamp';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LinearProgressIndeterminate from '../components/LinearProgressIndeterminate';
import DrawerHeader from '../components/NavigationHeader';
import { styled } from '@mui/material/styles';

import SearchResultDocs from '../components/SearchResultDocs';
import SearchAppBar from '../components/SearchAppbar';
import { useAppContext } from '../contexts/AppContext';
import config from '../config';
const drawerWidth = config.ui_settings.drawer_width;

export default function SearchPage() {
    const { doc_results, query, drawerOpen, pageTabs, activeTab, docsResultsLoading } = useAppContext();  
    const [ts_docsResultsLoading, setTSDocsResultsLoadingStatus] = React.useState(false);
    const [open_docs, setOpen_docs] = React.useState(false);
    const [project, setProject] = useState('all');

    const handleProjectSelection = (event) => {
        setProject(event.target.value);
    };

    const handleClick_Docs = () => {
        setOpen_docs(!open_docs);
    };

    useEffect ( () => {
        console.log("Query changed!:", query);
    }, [query]
    );

    useEffect(() => {
        console.log("ES Results changed!");
        console.log('ES Result Count:', doc_results.length);
        setTSDocsResultsLoadingStatus(Date().valueOf());
        }, 
        [doc_results]
    );

    const DocList = () => {
        if(doc_results.length > 0) {
            return(
                <Box>
                    <ListItemButton onClick={handleClick_Docs}>
                        <ListItemIcon>
                            <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <div>
                                <Typography variant="h5" sx={{fontWeight:'bold', padding:2}}>
                                    Ergebnisse in '{project}{/* {pageTabs[activeTab]} */}', für '{query}' ({doc_results.length})
                                </Typography>
                                <TimeStamp count={doc_results.length} timeStamp={ts_docsResultsLoading}/>
                            </div>  
                        } />
                        {open_docs ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open_docs} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {doc_results.map((result, index) => (
                                <SearchResultDocs key={index} matchObject={result}/>
                            ))}
                        </List>
                    </Collapse>
                </Box>
            )          
        }
    };

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
        ({ theme, drawerOpen }) => ({
          flexGrow: 1,
          padding: theme.spacing(0),
          marginLeft: `calc(${theme.spacing(8)} + 1px)`,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(drawerOpen && {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `calc(${drawerWidth}px)`,
          }),
        }),
      );

    return (

        <Main drawerOpen={drawerOpen}>
            <DrawerHeader />
            <LinearProgressIndeterminate isLoading={docsResultsLoading}/>
            <SearchAppBar project={project} onProjectSelection={handleProjectSelection}/>
            <DocList />
            <Divider />
      </Main>
        
    );
}