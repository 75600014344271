import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SearchToggle from './ACSearchToggle';
import ACAssign from './ACAssign';
import { isEqual } from 'lodash'; // Using lodash for deep comparison

const ACMapping = ({onDataChanged, document, selectedProject }) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [assignments, setAssignments] = useState({});
  const [functionSearchTerm, setFunctionSearchTerm] = useState('');


  useEffect(() => {
    // Whenever assignments change, notify the parent component
    const enrichedAssignments = selectedDocuments.map(doc => ({
      nextcloud_id: doc.file_metadata.id,
      name: doc.file_metadata.name,
      path: doc.file_metadata.path,
      acceptance_criterias: (assignments[doc.file_metadata.id] || []).map(assignment => assignment.name)
    }));

    onDataChanged(enrichedAssignments);

  }, [assignments, selectedDocuments]);

  useEffect(() => {
    if (selectedDocuments.length === 0) {
      setAssignments({});
    }
  }, [selectedDocuments]);

  useEffect(() => {
    if (!document || document.length === 0) return;
  
    const newSelectedDocuments = document.map(doc => ({
      file_metadata: {
        id: doc.nextcloud_id,
        name: doc.name,
        path: doc.path
      }

    }));
  
    const newAssignments = document.reduce((acc, doc) => {
      acc[doc.nextcloud_id] = doc.acceptance_criterias.map(criterion => ({ name: criterion }));
      return acc;
    }, {});

    const existingIds = selectedDocuments.map(doc => doc.file_metadata.id);
    const uniqueNewDocuments = newSelectedDocuments.filter(
      doc => !existingIds.includes(doc.file_metadata.id)
    );

    if(uniqueNewDocuments.length > 0) {
      const mergeUniqueDocuments = [...selectedDocuments, ...uniqueNewDocuments];
      setSelectedDocuments(mergeUniqueDocuments);
    } 

    if (!isEqual(newAssignments, assignments)) {
      setAssignments(newAssignments);
    }
  }, [document]);
  

  const handleDocumentSelect = (documents) => {
    setSelectedDocuments((prev) => {
      const newDocs = documents.filter(
        (doc) => !prev.some((existingDoc) => String(existingDoc.file_metadata.id) === String(doc.file_metadata.id))
      );
      return [...prev, ...newDocs];
    });
  };
  

  const handleClearDocuments = () => {
    setSelectedDocuments([]);
  };

  const handleFunctionAssign = (docId, criteria) => {
    setAssignments((prev) => ({
      ...prev,
      [docId]: criteria,
    }));
  };

  const handleRemoveDocument = (doc) => {
    setSelectedDocuments((prev) => prev.filter((d) => d.file_metadata.id !== doc.file_metadata.id));
    setAssignments((prev) => {
      const newAssignments = { ...prev };
      delete newAssignments[doc.file_metadata.id];
      return newAssignments;
    });
  };

  const handleFunctionSearchChange = (term) => {
    setFunctionSearchTerm(term);
  };

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="h6" align="left" gutterBottom>
        Referenz Dokumenten
      </Typography>
      <Typography variant="subtitle1" align="left" gutterBottom sx={{ mb: 2 }}>
        Wissensbasen und Ressourcen, auf die im Scenario Bezug genommen wird.
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <SearchToggle
          initialIsDocumentSearch={true}
          onSelect={handleDocumentSelect} 
          onClearSelected={handleClearDocuments} 
          project={selectedProject} 
        />
        </Grid>
        <Grid item xs={12}>
          <ACAssign
            selectedDocuments={selectedDocuments}
            onAssign={handleFunctionAssign}
            onRemoveDocument={handleRemoveDocument}
            onSearchChange={handleFunctionSearchChange}
            searchTerm={functionSearchTerm}
            assignments={assignments} // Pass assignments as prop
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ACMapping;
