import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { TextField, Box, IconButton, InputAdornment, LinearProgress } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DeleteConfirmationModal from './DeleteConfirmationModal';
import ReportGridRowContextMenu from './ReportGridRowContextMenu';
import { useAppContext } from '../contexts/AppContext';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import config from '../config';

export default function ReportInstancesGrid() {
  const { removeReportResult, fetchReportResults } = useAppContext();
  const [deleteRow, setDeleteRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportResults, setReportResults] = useState([]);
  const navigate = useNavigate();

  const loadReportResults = async () => {
    setIsLoading(true);
    try {
      const fetchedResults = await fetchReportResults();
      if (fetchedResults) {
        setReportResults(fetchedResults);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch report templates', error);
    }
  };

  useEffect(() => {
    loadReportResults();
  }, []);

  const handleRowClick = (object, event) => {
    // Check if the event was triggered by clicking on the action column
    if (event.target.closest('.action-cell-click')) {
      // Prevent row click when clicking inside the action cell
      event.stopPropagation();
    } else {
      // Normal row click action
      navigate(`/reports/instances/${object.row.name}?project=${encodeURIComponent(object.row.project.split('/').pop())}&creation_date=${object.row.creation_date}`);
      console.log('Navigating to details of row:', object.row.name);
    }
  };

  const handleDeleteOptionSelect = (row) => {
    setDeleteRow(row);
    setDeleteModalOpen(true);
    console.log('Prepare to delete row:', row.id);
  };

  const handleConfirmDelete = () => {
    /* removeReportInstance(deleteRow.id).then(() => { */
    removeReportResult(deleteRow.project, deleteRow.name, deleteRow.creation_date).then(() => {
      loadReportResults();
      console.log('Row deleted:', deleteRow);
      setDeleteModalOpen(false);
      setDeleteRow(null);
    });
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const columns = [
    /* { field: 'id', headerName: 'ID', flex: 0.5 }, */
    { field: 'project', headerName: 'Projekt', flex: 0.5 },
    { field: 'creation_date', headerName: 'Erstellungsdatum', flex: 0.5 },
    { field: 'name', headerName: 'Definition', flex: 0.75 },
    { field: 'description', headerName: 'Beschreibung', flex: 0.75 },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="action-cell-click">
          <IconButton onClick={() => handleDeleteOptionSelect(row)}>
            <DeleteForeverIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  // Using row index as a unique identifier
  const getRowId = React.useCallback((row) => `${row.name}-${row.creation_date}`, []);

  const filteredRows = reportResults.filter(row => row.name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div style={{ width: '100%' }}>

      {isLoading ? (
        <LinearProgress />
      ) : (

        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', margin: 1 }}>
            <TextField
              label="Suchen"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: '25%', marginRight: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <DataGridPro
            getRowId={getRowId}
            initialState={{
              columns: {
                columnVisibilityModel: { id: false },
              },
              pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
            }}
            pagination
            pageSizeOptions={config.ui_settings.pagination_page_size_options}
            rows={filteredRows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            checkboxSelection
            disableRowSelectionOnClick
            disableMultipleSelection
            onRowClick={handleRowClick}
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'lightgrey',
              },
              '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                outline: 'none', // Remove focus outline
              },
              '& .Mui-selected, & .MuiDataGrid-cell--selected, & .MuiDataGrid-row--selected': {
                backgroundColor: 'transparent', // Remove selection background
                color: 'inherit', // Ensure text color is consistent
              }
            }}
          />
        
          <DeleteConfirmationModal
            open={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={handleConfirmDelete}
            rowId={deleteRow}
          />
        </React.Fragment>
      )}
    </div>

  );
}
