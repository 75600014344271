import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const useApiErrorHandler = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleApiError = (error, logout, apiName) => {
        console.log(`Anfrage für ${apiName} fehlgeschlagen:`, error.message);

        const renderAction = (key) => (
            <Button color="inherit" size="small" onClick={() => { closeSnackbar(key); }}>
                Schließen
            </Button>
        );

        const createMessage = (message, apiName) => (
            <Typography>
                {message.split(apiName)[0]}
                <strong>{apiName}</strong>
                {message.split(apiName)[1]}
            </Typography>
        );
        
        if (error.response) {
            let message;
            switch (error.response.status) {
                case 401:
                    message = `Sitzung abgelaufen bei ${apiName}. Bitte erneut anmelden.`;
                    break;
                case 409:
                    message = error.response.data.detail || `Konflikt bei ${apiName} erkannt.`;
                    break;
                case 404:
                    message = error.response.data.detail || `Konflikt bei ${apiName} erkannt.`;
                    break;
                default:
                    message = createMessage(`Ein Fehler ist bei ${apiName} aufgetreten. Bitte versuchen Sie es später noch einmal.`, apiName);
                    break;
            }
            enqueueSnackbar(message, {
                variant: 'error',
                action: (key) => renderAction(key),
                preventDuplicate: true
            });
            if (error.response.status === 401) {
                setTimeout(logout, 3000);
            }
        } else {
            enqueueSnackbar(createMessage(`Ein Netzwerkfehler ist bei ${apiName} aufgetreten. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.`, apiName), {
                variant: 'error',
                action: (key) => renderAction(key),
                preventDuplicate: true
            });
        }
    };

    return handleApiError;
};
