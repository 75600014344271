import { parseISO, isValid, format } from 'date-fns';

export const convertToDate = (dateString) => {
    // Parse the date from an ISO 8601 string
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate) ? parsedDate : null;
};
  
export const convertToDateString = (date) => {
    return date ? format(date, 'yyyy-MM-dd') : '';
};