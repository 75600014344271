import * as React from 'react';
import Box from '@mui/material/Box';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Typography, Grid, Card, CardHeader, CardContent, Collapse, Avatar, 
    IconButton, Button, Link, Chip, List, ListItem, ListItemText
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import parse from 'html-react-parser';
/* import Highlighter from "react-highlight-words";
import Markdown from 'markdown-to-jsx'; */

import config from '../config';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

export default function SearchResultDocs(props) {
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const formatParseDate = (date) => {
        return `Datei verarbeitet am: ${date}`;
    }

    const formatFormat = (format) => {
        if (format) {
            return `Datei format: ${format.split('.')[1].toUpperCase()}`;
        }
    }

    const formatScore = (score) => {
        return "Relevanz: " + parseFloat(score).toFixed(2);
    };
    
    
    const getUrl = () => {
        if (props && props.matchObject && props.matchObject.file_metadata ) {
            let filepath = props.matchObject.file_metadata.path; //"/test_project1/Bautechnik/de_clds_30491.pdf"
            let filepaths_no_file = filepath.split('/').filter(entry => entry.trim() != '').slice(0,-1);
            return config.envs[config.active_env].domain + config.nextcloud_path + filepaths_no_file.join('/') + '&openfile=' + props.matchObject.file_metadata.id;
        } else {
            return "";
        }
    };

    function EmphasizedText({ htmlContent }) {
        const options = {
            replace: domNode => {
                if (domNode.name === 'em') {
                    return <em style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{domNode.children[0].data}</em>;
                }
            }
        };
    
        return (
            <Typography variant="body1" component="div">
                {parse(htmlContent, options)}
            </Typography>
        );
    }

/*     const getHighlights = (spans) => {
        if (spans ) {
            return spans.map(function(item) {
                return item['text'];
            });
        }
    }; */

    const { matchObject } = props;
/*     const { text_sections, text } = matchObject; */
    const highlightCount = matchObject.text_sections.length;
    const contextWords = config.ui_settings.search_doc_result_padding;

/*     const createShortenedText = (text, spans, contextChars) => {
        let shortenedText = '';
        let lastIndex = 0;
    
        spans.forEach((span) => {
          const { start, end } = span;
          const contextStart = Math.max(0, start - contextChars);
          const contextEnd = Math.min(text.length, end + contextChars);
    
          shortenedText += text.slice(contextStart, end) + '...';
          lastIndex = contextEnd;
        });
    
        return shortenedText;
    }; */

    /* const createSpanWithContext = (text, span, contextChars) => {
        const { start, end } = span;
        const contextStart = Math.max(0, start - contextChars);
        const contextEnd = Math.min(text.length, end + contextChars);
    
        const beforeText = text.slice(contextStart, start);
        const spanText = text.slice(start, end);
        const afterText = text.slice(end, contextEnd);
    
        return `${beforeText}${spanText}${afterText}`;
    }; */

    // Helper function to get the index of the word by character position
    const getWordIndexByCharIndex = (wordIndices, charIndex) => {
        return wordIndices.findIndex(word => word.start <= charIndex && word.end >= charIndex);
    };

    const createSpanWithContext = (text, span, contextWords) => {
        const words = text.split(/\s+/);
        let position = 0;
        const wordIndices = words.map(word => {
          const start = position;
          const end = position + word.length;
          position += word.length + 1; // account for the space
          return { word, start, end };
        });
    
        const startWordIndex = getWordIndexByCharIndex(wordIndices, span.start);
        const endWordIndex = getWordIndexByCharIndex(wordIndices, span.end);
    
        // Define the word indices for the context window
        const contextStartIndex = Math.max(0, startWordIndex - contextWords);
        const contextEndIndex = Math.min(wordIndices.length - 1, endWordIndex + contextWords);
    
        const contextText = wordIndices.slice(contextStartIndex, contextEndIndex + 1).map(w => w.word).join(' ');
        const highlightText = wordIndices.slice(startWordIndex, endWordIndex + 1).map(w => w.word).join(' ');
    
        return { contextText, highlightText };
    };

/*     const shortenedText = createShortenedText(text, spans, contextChars); */

    return (
        <Box sx={{ width: '100%', textAlign:'left', padding: '2em 0.5em 2em 0.5em'}}>       
            <Card>
                <CardHeader
                    sx={{paddingBottom:1}}
                    avatar={
                    <Avatar sx={{ bgcolor: 'black' }} aria-label="recipe">
                        D
                    </Avatar>
                    }
                    action={
                        <Box>
                            <Link sx={{padding:2}}
                            href={ getUrl() } target="_blank">
                                <Typography variant='button' sx={{padding:2, marginBottom:'20px'}}>
                                    Dokument öffnen 
                                </Typography>
                                <OpenInNewIcon />
                            </Link>
                            
                            <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>

                            <IconButton disabled aria-label="pin">
                                <PushPinIcon />
                            </IconButton>
                            
                        </Box>
                    }
                    title={
                        <Box>
                            <Button 
                            onClick={handleExpandClick}
                            variant='text'>
                                <Typography 
                                variant="h6">
                                    <strong>{props.matchObject.file_metadata.name}</strong>
                                </Typography>
                            </Button>
                            <Typography 
                            variant="body2"
                            sx={{padding:1}}>
                                {formatParseDate(props.matchObject.creation_date)}
                            </Typography>
                            {/* Chip displaying the client value */}
                            {props.matchObject.client && (
                                <Chip sx={{margin:0.5}} label={props.matchObject.client} />
                            )}
                            {/* Chip displaying the project value */}
                            {props.matchObject.project && (
                                <Chip sx={{margin:0.5}} label={props.matchObject.project} />
                            )}
                            
                        </Box>
                    }
                    subheader={
                        <Box sx={{padding:1}}>
                            {/* {formatScore(props.matchObject.max_score)} */}
                            {formatFormat(props.matchObject.format)}
                        </Box>
                        
                    }
                >
                </CardHeader>       

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent  sx={{ 
                        margin: 1, // default margin
                        [theme.breakpoints.up('sm')]: { margin: 2 },
                        [theme.breakpoints.up('md')]: { margin: 3 },
                        [theme.breakpoints.up('lg')]: { margin: 4 }
                    }}
                    >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                                Dokument gefunden im Pfad:
                            </Typography>
                            <Typography sx={{whiteSpace: 'pre-wrap'}}>
                                {props.matchObject.file_metadata.path}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                            Übereinstimmungen in Dokument gefunden: {highlightCount}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography variant="body2" color="text.secondary">
                            Suchanfrage gefunden im Kontext:
                            </Typography> */}
                            <List>
                            {matchObject.text_sections.map((match, index) => {
                                /* const { contextText, highlightText } = createSpanWithContext(text, span, contextWords); */
                                return (
                                <ListItem key={index}>
                                    <ListItemText
                                    primary={
                                        <div>
                                        <strong>{index + 1}. </strong>
                                        <strong>Auf Seite {match.page_number} gefunden:</strong>
                                        {/* <Typography>{match.highlight}</Typography> */}
                                        {/* <Markdown content={match.highlight}/> */}
                                        <EmphasizedText htmlContent={match.highlight} />
                                        {/* <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: match.highlight }} /> */}
                                        {/* <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={[highlightText]}
                                            autoEscape={true}
                                            textToHighlight={contextText}
                                        /> */}
                                         
                                        {formatScore(match.score)}
                                        </div>
                                    }
                                    />
                                </ListItem>
                                );
                            })}
                            </List>
                            {/* <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={spans.map((span) => text.slice(span.start, span.end))}
                            autoEscape={true}
                            textToHighlight={shortenedText}
                            /> */}
                        </Grid>
                    </Grid>


                    {/* <div key={0}>
                        
                        <Grid container spacing={2}>
                            

                            <Grid item xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                    Suchanfrage gefunden im Kontext:
                                </Typography>
                                <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={getHighlights(props.matchObject.spans)}
                                    autoEscape={true}
                                    textToHighlight={props.matchObject.text}
                                />
                            </Grid>
                        </Grid>
                    </div> */}
                </CardContent>
                </Collapse>
            </Card>
        </Box>  
    );
}
