import React from 'react';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom'; // Import Outlet
import { useAppContext } from '../contexts/AppContext';
import DrawerHeader from '../components/NavigationHeader';
import { LinearProgress } from '@mui/material';
import config from '../config';
const drawerWidth = config.ui_settings.drawer_width;

export default function ReportPage() {
  const { drawerOpen, reportTemplatesLoading, reportInstancesLoading } = useAppContext();

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
    ({ theme, drawerOpen }) => ({
      flexGrow: 1,
      padding: theme.spacing(0),
      marginLeft: `calc(${theme.spacing(8)} + 1px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(drawerOpen && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `calc(${drawerWidth}px)`,
      }),
    })
  );

  return (
    <Main drawerOpen={drawerOpen}>
      <DrawerHeader />
      {!reportTemplatesLoading && !reportInstancesLoading ? 
          <Outlet />: 
          <LinearProgress />
      }
        
      
    </Main>
  );
}
