import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, Chip, IconButton, TextField, Card, CardContent, 
  Divider, Box, FormControl, InputLabel, MenuItem, Select, Tooltip, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import CloseIcon from '@mui/icons-material/Close';
import RecurringEventControls from './RecurringEventControls'; 

import ReportMethodDropdown from './ReportMethodDropdown';

import ReportInstanceListView from './ReportInstanceListView';
import TaskListView from './TaskListView';

import { useAppContext } from '../contexts/AppContext';

const EditReportTemplate = ({ editData, userProjects, handleEditChange, isViewAction, handleLocalSaveClick, onBackClick }) => {
/*   const [status, setStatus] = useState(editData.status || 'inactive'); */
  const { fetchReportInstances, getExtractedData } = useAppContext();
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [reportInstances, setReportInstances] = useState([]);
  const [project, setProject] = useState(null);

  const handleProjectSelection = (event) => {
    console.log('Setting project: ', event.target.value);
    setProject(event.target.value);
  };

  const handleSelectedMethodChange = async (method) => {
    setSelectedMethod(method);
    if (method === 'smart' && project) {
      const [extracted_tasks, summaries] = await getExtractedData(project);
      setTasks(extracted_tasks);
    } else if (method === 'Bewertung') {
      const reportInstanceList = await fetchReportInstances();
      setReportInstances(reportInstanceList);
    }
  };

  const VerticalDivider = styled(Divider)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }));

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} direction="column">
        
        {/* First Row */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
      
                  <Typography variant="h6" align="left">Planung</Typography>
                  <TextField
                    label="Titel"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={editData.title}
                    onChange={(e) => handleEditChange('title', e.target.value)}
                  />
                  <TextField
                    label="Beschreibung"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={editData.description}
                    onChange={(e) => handleEditChange('description', e.target.value)}
                  />

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, marginBottom: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                      <DatePicker
                        label="Startdatum"
                        value={editData.start_date}
                        onChange={(newValue) => handleEditChange('start_date', newValue)}
                        renderInput={(params) => <TextField {...params} sx={{ width: '48%' }} />}
                      />
                      <DatePicker
                        label="Enddatum"
                        value={editData.end_date}
                        onChange={(newValue) => handleEditChange('end_date', newValue)}
                        renderInput={(params) => <TextField {...params} sx={{ width: '48%' }} />}
                      />
                    </LocalizationProvider>
                  </Box>

                  <RecurringEventControls />
                </Grid>

                <VerticalDivider orientation="vertical" flexItem />              
  
                <Grid item xs={8.7}>
                  <Grid item xs>
                    <Card>
                      <CardContent>
                        <Box sx={{marginTop:2}}>
                            {/* Title */}
                          <Typography variant="h6" align="left" gutterBottom>
                            Bericht Art
                          </Typography>

                          {/* Subtitle */}
                          <Typography variant="subtitle1" align="left" gutterBottom>
                            Wählen Sie die Art des Berichts aus, den Sie erstellen möchten.
                          </Typography>

                          <FormControl fullWidth margin="normal">
                            <InputLabel>Projekt</InputLabel>
                            <Select
                              labelId="project-label"
                              id="project"
                              value={project || ''}
                              onChange={handleProjectSelection}
                              label="Projekt"
                              sx={{ backgroundColor: 'white' }}
                            >
                              {userProjects.map((projectItem) => (
                                <MenuItem key={projectItem} value={projectItem}>{projectItem}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <ReportMethodDropdown
                            selectedMethod={selectedMethod}
                            setSelectedMethod={handleSelectedMethodChange}
                            project={project}
                          />
                        </Box>

                        <Box sx={{marginTop:2}}>
                          {/* Title */}
                          <Typography variant="h6" align="left">
                            {selectedMethod === 'smart' ? 'Verfügbare Aufgaben' : 'Verfügbare Bewertungen'}
                          </Typography>

                          {/* Subtitle */}
                          <Typography variant="subtitle1" align="left" gutterBottom>
                            Wählen Sie die Auswertungen aus, die Sie für Ihren Bericht kombinieren möchten.
                          </Typography>
                        </Box>

                        
                        {project && selectedMethod && (
                          <>
                            {selectedMethod === 'smart' ? (
                              tasks && (
                                <TaskListView rows={tasks} />)
                            
                            ) : (  
                              reportInstances && (
                                <ReportInstanceListView rows={reportInstances} filterMethod={selectedMethod} />
                              )
                              
                            )}
                          </>
                        )}

                        <Box sx={{marginTop:2}}>
                          {/* Title */}
                          <Typography variant="h6" align="left" gutterBottom>
                            Anweisungen
                          </Typography>

                          {/* Subtitle */}
                          <Typography variant="subtitle1" align="left" gutterBottom>
                            Zusätzliche Hinweise für den Bericht. Dies können Fragen, Anweisungen oder weitere zu berücksichtigende Informationen sein.
                          </Typography>

                          {/* TextField modified for large texts */}
                          <TextField
                            label="Anweisungen"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={13}
                            value={editData.user_instructions}
                            onChange={(e) => handleEditChange('user_instructions', e.target.value)}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
          
                </Grid>
    
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>

          <Button 
            variant="contained" 
            color="primary" 
            disabled={true}
            onClick={handleLocalSaveClick} // Use the local handler 
            style={{ margin: '8px' }}>
            Speichern
          </Button>
          <IconButton onClick={onBackClick}>
            <CloseIcon />
          </IconButton>
        </Grid>

      </Grid>
    </div>
  );
};

export default EditReportTemplate;

