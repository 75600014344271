import React, { useState } from 'react';
import {
  TextField, List, ListItem, ListItemText, Checkbox, Button,
  Typography, IconButton, CircularProgress, InputAdornment, Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from "../contexts/AuthContext";
import config from '../config';

const DocumentSearch = ({ onSelect, project }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [loading, setLoading] = useState(false);

  const { /* getSearchResultsInDocsStateless, */ getSearchResultsInDocsStatelessV2 } = useAppContext();
  const { currentTenant } = useAuth();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    if (!project) return; // Ensure project is set before searching
    setLoading(true);
    // Construct the new project path
    const referenceProjectPath = `/${currentTenant}/Vorgaben Vorlagen und Richtlinien`;
    const currentProjectPath = `/${currentTenant}/${project}`;
    // Combine the new path with the existing project
    const project_list = `${currentProjectPath}`;//,${referenceProjectPath}`;
    const results = await getSearchResultsInDocsStatelessV2(
      config.envs[config.active_env].domain + config.api_path,
      20,
      1,
      project_list,
      searchTerm/* ,
      "/" */
    );
    if (results) setFilteredDocuments(results);
    setLoading(false);
  };
  

  const handleSelect = (doc) => {
    setSelectedDocs((prev) => {
      const index = prev.findIndex((d) => d.file_metadata.id === doc.file_metadata.id);
      if (index >= 0) {
        // Document is already selected, remove it from the array
        return prev.filter((_, i) => i !== index);
      } else {
        // Document is not selected, add it to the array
        return [...prev, doc];
      }
    });
  };

  const handleConfirmSelection = () => {
    // Ensure onSelect is called with an array of documents
    onSelect(selectedDocs);
    setFilteredDocuments([]);
    setSearchTerm('');
    setSelectedDocs([]);
  };

  const handleClearResults = () => {
    setFilteredDocuments([]);
    setSearchTerm('');
  };

  if (!project) {
    console.log('Project is not Set for Report.');
    return <Typography variant="body">Bitte wählen Sie ein Projekt aus, um Dokumente zu suchen.</Typography>;
  }

  return (
    <React.Fragment>
      <TextField
        label="Dokumenten Suchen nach..."
        value={searchTerm}
        onChange={handleSearchChange}
        disabled={!project}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch} disabled={!project}>
                {loading ? <CircularProgress size={24} /> : <SearchIcon />}
              </IconButton>
              <IconButton onClick={handleClearResults} disabled={!project || !searchTerm}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ maxHeight: 300, overflow: 'auto', marginTop: 2 }}>
        <List>
          {filteredDocuments.map((doc) => (
            <ListItem key={doc.file_metadata.id} button onClick={() => handleSelect(doc)}>
              <Checkbox
                checked={selectedDocs.some((d) => d.file_metadata.id === doc.file_metadata.id)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={doc.file_metadata.name} secondary={doc.file_metadata.path} />
              {/* <Typography>{`Suchrang: ${doc.max_score}`}</Typography> */}
            </ListItem>
          ))}
        </List>
      </Box>
      {filteredDocuments.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            variant="contained"
            onClick={handleConfirmSelection}
            disabled={!selectedDocs.length}
          >
            Zum Bericht hinzufügen
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export default DocumentSearch;
