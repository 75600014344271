import * as React from 'react';
import Box from '@mui/material/Box';
import PushPinIcon from '@mui/icons-material/PushPin';
import { List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useAppContext } from '../contexts/AppContext';

/* import config from '../config'; */

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

export default function SearchResultQA(props) {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const formatScore = (score) => {
        return +score.toFixed(2);
    }; 

    const { removeItemFromQAResults } = useAppContext();  

    const handleRemoveClick = () =>  {
        removeItemFromQAResults(props.index)
    }

    return (
        <Box sx={{ width: '100%', textAlign:'left', padding: '2em 0.5em 2em 0.5em'}}>       
            <Card>
                <CardHeader
                    sx={{paddingBottom:1}}
                    avatar={
                    <Avatar sx={{ bgcolor: 'black' }} aria-label="recipe">
                        {props.index + 1}
                    </Avatar>
                    }
                    action={
                        <Box>
                            <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>

                            <IconButton disabled aria-label="pin">
                                <PushPinIcon />
                            </IconButton> 
                        </Box>
                    }
                    title={
                        <Box>
                            <Button 
                            onClick={handleExpandClick}
                            variant='text'>
                                <Typography 
                                variant="h6">
                                    <strong>
                                        Frage/Aussage: {props.question}
                                    </strong>
                                </Typography>
                            </Button>
                        </Box>
                    }
                    subheader={
                        <Typography 
                        variant="h6">
                            Antwort mit Kontext von {props.answer.source_nodes.length} Quellen generiert:
                        </Typography>
                    }
                >
                </CardHeader>       

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>           
                        <Box sx={{
                            width: '90%',
                            margin: '25px',
                            marginLeft: '4rem',
                            display: 'flex',
                            flexDirection:'column',
                            justifyContent:'space-between',
                            alignItems: 'top'
                        }}>
                           
                            <List 
                            variant="body2"
                            sx={{padding:1}}>
                                {props.answer.source_nodes.map((source, index) => ( 
                                    <Box key={index}>
                                        <Typography sx={{whiteSpace: 'pre-wrap'}}>
                                            <strong>Dokument: </strong>
                                            {source.node.metadata.file_name} 
                                            , <strong>Seite: </strong>
                                            {source.node.metadata.page_label}, <strong>Score:</strong> 
                                            {formatScore(source.score)}
                                            <Link component="button" sx={{padding:0, margin:0}}
                                            href="" target="_blank">
                                                <Typography variant='button' sx={{paddingLeft:2}}>
                                                    Dokument öffnen
                                                </Typography>
                                                <OpenInNewIcon />
                                            </Link>
                                        </Typography>
                                    </Box>
                                 ))}
                            </List>
                        
                            <Typography>
                                {props.answer.response}
                            </Typography>
                        </Box> 
                        <Button variant="outlined" onClick={handleRemoveClick}>von Verlauf löschen <DeleteForeverIcon sx={{margin:0.5}}/></Button>   
                    </CardContent>
                </Collapse>
            </Card>
        </Box>  
    );
}
