import React, { useState, useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import ACDocumentSearch from './ACDocumentSearch';
import ACFolderSearch from './ACFolderSearch';

const SearchToggle = ({ initialIsDocumentSearch, onSelect, onClearSelected, project }) => {
  const [isDocumentSearch, setIsDocumentSearch] = useState(initialIsDocumentSearch);

  const toggleSearch = () => {
    setIsDocumentSearch((prev) => !prev);
  };

  useEffect(() => {
    // Ensure the component state is in sync with the prop if the parent updates it
    setIsDocumentSearch(initialIsDocumentSearch);
  }, [initialIsDocumentSearch]);

  return (
    <Box>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">
          {isDocumentSearch ? 'Dokumentensuche' : 'Ordnersuche'}
        </Typography>
        <Button variant="contained" color="primary" onClick={toggleSearch}>
          {isDocumentSearch ? 'Zu Ordnersuche wechseln' : 'Zu Dokumentensuche wechseln'}
        </Button>
      </Box>

      <Box>
        {isDocumentSearch ? 
        <ACDocumentSearch 
          onSelect={onSelect} 
          onClearSelected={onClearSelected} 
          project={project}
        /> : 
        <ACFolderSearch 
          onSelect={onSelect} 
          onClearSelected={onClearSelected} 
          project={project}
        />}
      </Box>
    </Box>
  );
};

export default SearchToggle;
