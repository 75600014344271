import { marked } from 'marked';

const reportTemplate = (data) => {
    // Convert markdown to HTML
    const summaryHtml = marked(data.text || "");

    // Filter out 'markdown_report' and prepare documents data
    const documents = Object.entries(data.document).filter(([key, _]) => key !== 'markdown_report');

    // Function to get the traffic light symbol based on the score
    function getTrafficLight(score) {
        switch(score) {
        case 0: return '🔴'; // Red
        case 1: return '🟡'; // Yellow
        case 2: return '🟢'; // Green
        default: return '';  // No symbol for undefined scores
        }
    }

    // Generate HTML content for each document using the updated criteria rendering
    const documentsHtml = documents.map(([key, doc]) => {
        const criteriaHtml = doc.acceptance_criterias.map((criteria, index) => `
            <ol start="${index + 1}">
                <li>${criteria.name}
                    <ul>
                        ${criteria.requirements.map(req => `
                            <li>
                                <strong>Anforderung:</strong> ${req.name}
                                <br>
                                <strong>Bewertung:</strong> ${getTrafficLight(req.score)} ${req.score}
                                <br>
                                <strong>Begründung:</strong> ${req.justification}
                            </li>
                        `).join('')}
                    </ul>
                </li>
            </ol>
        `).join('');
    
        return `
            <div class="document">
                <h4>${doc.name}</h4>
                <p>Path: ${doc.path}</p>
                <ol>${criteriaHtml}</ol>
            </div>
        `;
    }).join('');
    

    return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Monthly Management Report</title>
            <style>
                body { font-family: Arial, sans-serif; margin: 40px; }
                header { display: flex; justify-content: space-between; align-items: center; }
                footer { position: fixed; bottom: 0; left: 0; width: 100%; background: #333; text-align: right; padding: 20px; color: white; }
                h1, h2, h3, h4, h5, h6 { color: #333; }
                .title { text-align: center; margin-bottom: 20px; }
                .section { margin-bottom: 20px; }
                .section h2 { border-bottom: 2px solid #333; padding-bottom: 5px; }
                .section p { margin: 5px 0; }
                .compact-info { display: flex; flex-wrap: wrap; }
                .compact-info div { width: 50%; }
            </style>
        </head>
        <body>
            <header>
                <div><b>Version:</b> ${data.version}</div>
                <div><b>Methode:</b> ${data.method}</div>
                <p>Ausführungsdatum: ${data.currentDate}</p>
            </header>
    
            <div class="title">
                <h1><b>Titel:</b> ${data.name}</h1>
            </div>
    
            <div class="section">
                <h2>Bericht Details</h2>
                <div class="compact-info">
                    <div><b>Erstellungsdatum Definition:</b> ${data.creation_date}</div>
                    <div><b>Tenant:</b> ${data.tenant}</div>
                    <div><b>Projekt:</b> ${data.project}</div>
                    <div><b>Beschreibung:</b> ${data.description}</div>
                </div>
            </div>
    
            <div class="section">
                <h2>Verweise / Quellen</h2>
                ${documentsHtml}
            </div>
    
            <div class="section">
                <h2>Zusammenfassung der Ergebnisse</h2>
                <p>${summaryHtml}</p>
            </div>    
    
            <footer>
                <div>
                    <p>Nukleus AG</p>
                    <p>digital intelligence</p>
                </div>
            </footer>
        </body>
        </html>
    `;
};

export default reportTemplate;
