import React, { useState, useEffect } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Menu, MenuItem, Button,Typography, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAppContext } from '../contexts/AppContext';
import ProjectsLinkToFiles from './ProjectsLinkToFiles';
import ProjectOverviewTimelineChart from './ProjectOverviewTimelineChart';
import config from '../config';

const StyledMenuItem = styled(MenuItem)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  color: color || 'inherit', // Apply color if provided
  '& .icon': {
    marginRight: theme.spacing(1),
    color: color || 'inherit', // Apply color to the icon as well
  },
}));

const CustomContextMenu = ({ anchorEl, onClose, onMenuItemClick }) => {
  const options = [
    { id:'view', label: 'View', icon: <VisibilityIcon /> },
    { id:'edit', label: 'Edit', icon: <EditIcon /> },
    { id:'delete', label: 'Delete', icon: <DeleteForeverIcon />, color: 'red' }, // Add color property for 'delete'
  ];

  const handleMenuItemClick = (option) => {
    onMenuItemClick(option);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {options.map((option) => (
        <StyledMenuItem
          key={option.label}
          onClick={() => handleMenuItemClick(option)}
          style={option.color ? { color: option.color } : {}} // Apply the color style conditionally
        >
          <div className="icon" style={option.color ? { color: option.color } : {}}>{option.icon}</div> {/* Color the icon as well */}
          <Typography variant="inherit" noWrap>
            {option.label}
          </Typography>
        </StyledMenuItem>
      ))}
    </Menu>
  );
};

const RowContextMenu = ({ row, onMenuItemClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleContextMenuClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleContextMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <CustomContextMenu
        anchorEl={anchorEl}
        onClose={handleContextMenuClose}
        onMenuItemClick={onMenuItemClick}
      />
    </>
  );
};

const ListPage = ({ rows, onRowViewClick, onOptionSelect, onAddNewProject  }) => {
  const { fetchReportResults } = useAppContext(); // Fetch the context and the function
  const [reportsData, setReportsData] = useState(null); // Store reports by project ID
  const [loading, setLoading] = useState(true); // Track loading state


  // Function to calculate average score per acceptance criteria for a report
  const calculateAverageScore = (documents) => {
    const scores = [];

    Object.values(documents).forEach((doc) => {
      doc.acceptance_criterias.forEach((criteria) => {
        const validScores = criteria.requirements
          .map((requirement) => requirement.score)
          .filter((score) => score !== -1);

        const totalScore = validScores.reduce((acc, score) => acc + score, 0);
        const averageScore = validScores.length ? totalScore / validScores.length : 0;

        scores.push({ name: criteria.name, averageScore });
      });
    });

    return scores;
  };

  // Function to prepare reports data by project
  const prepareReportsData = (reports) => {
    const dataByProject = {};

    reports.forEach((report) => {
      const { project, document } = report;

      if (!dataByProject[project]) {
        dataByProject[project] = [];
      }

      dataByProject[project].push({
        creation_date: report.creation_date,
        name: report.name,
        document: report.document,
        project: report.project,
        scores: calculateAverageScore(report.document), // Include the calculated average score
      });
    });

    setReportsData(dataByProject);
    setLoading(false); // Data is now loaded
  };

  // Fetch reports when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const reports = await fetchReportResults(); // Fetch all reports
        prepareReportsData(reports); // Prepare data by project
      } catch (error) {
        console.error('Failed to fetch report results:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchReportResults]);

  // Using row index as a unique identifier
  const getRowId = (row, index) => `${row.name}`;//-${index}`;

  const columns = [
    { field: 'id', headerName: 'ID', flex:0.5 },
    { field: 'name', headerName: 'Name', flex:1, },
    { field: 'path', headerName: 'Dokumentablage', flex:1.5, 
      renderCell: (params) => ( 
        <ProjectsLinkToFiles path={params.row.path}/>
      ),
    },
    { field: 'project_category', headerName: 'Zielkategorie', flex:0.75, },
    { field: 'project_class', headerName: 'Nutzungskategorie', flex:0.75, },
    { field: 'start_date', headerName: 'Startdatum', flex:0.5, },
    { field: 'end_date', headerName: 'Enddatum', flex:0.5, },
    { field: 'profile', headerName: 'Profil', flex:0.5, }, 
    {
      field: 'timeline',
      headerName: 'Berichte',
      flex:3,
      renderCell: (params) => {
        // Get the ID based on the same logic as getRowId
        const projectId = getRowId(params.row);
            
        // If data is still loading, show a loading spinner
        if (loading) {
          return <CircularProgress />;
        }

        // Get reports for the current project based on the project ID
        const projectReports = reportsData && reportsData[projectId] ? reportsData[projectId] : [];
        return <ProjectOverviewTimelineChart reports={projectReports} />; // Render the Recharts timeline for each project
      },
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 75,
      sortable: false,
      renderCell: ({ row }) => (
        <RowContextMenu row={row} onMenuItemClick={(option) =>  {
          onOptionSelect(option.id, row);
        }} />
      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    name: false,
    project_category: true,
    project_class: false,
    profile: true,
  });

  return (
    <div style={{ width: '100%', overflow: 'visible' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
        <Button variant="contained" color="primary" onClick={onAddNewProject}>
          Projekt Erstellen
        </Button>
      </div>
      <DataGridPro 
      rowHeight={120}
      getRowId={(row) => getRowId(row)}
      columns={columns}
      rows={rows}
      slots={{ toolbar: GridToolbar }}
      checkboxSelection
      disableRowSelectionOnClick
      disableMultipleSelection 
      columnVisibilityModel={columnVisibilityModel} // Bind state to visibility model
      onColumnVisibilityModelChange={(newModel) => {
        setColumnVisibilityModel(newModel); // Allow changes from UI
      }}
      initialState={{
        pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
      }}
      pagination
      pageSizeOptions={config.ui_settings.pagination_page_size_options}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: 'lightgrey', // Apply light grey background to headers
        },
      }}
    />


    </div>
  );
};

export default ListPage;
