import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useAppContext } from '../contexts/AppContext';
/* import { isModuleAvailable } from './Navigation'; */

const ProtectedRoute = ({ children /* moduleName */ }) => {
    const { isLoggedIn, isAuthorized, handleOAuthCallback, setToken, userTenants, setTenants, setCurrentTenant, currentTenant, isTokenValid, token, setIsLoggedIn, setIsAuthorized } = useAuth();
    const { getUserData, userProjects, setUserProjects, setUserData } = useAppContext();
    const navigate = useNavigate();

    const loadAuthAndTenant = async () => {
        const storedToken = JSON.parse(sessionStorage.getItem('authToken'));
        const storedUserTenants = JSON.parse(sessionStorage.getItem('userTenants')); 
        if (storedToken && isTokenValid(storedToken)) {
            if (storedToken && !token) {
                setToken(storedToken);
            }
            console.log('Token and UserGroups are present and valid, checking tenants...');
            setIsAuthorized(true);

            // Check if the tenant lists are valid (not null and length > 0)
            const validStoredUserTenants = Array.isArray(storedUserTenants) && storedUserTenants.length > 0;
            const validUserTenants = Array.isArray(userTenants) && userTenants.length > 0;

            if (validStoredUserTenants || validUserTenants) {
                /* setIsLoggedIn(true); */
                

                // Update the context if only storedUserTenants are present
                if (validStoredUserTenants && !validUserTenants) {
                    setTenants(storedUserTenants);
                }

                
            } else {
                console.log('Tenant list is unknown, redirecting to login');
                navigate('/login');
            }
        } else {
            console.log('Token is invalid, redirecting to login');
            navigate('/login');
        }
    };

    const loadUserData = async () => { 
        const storedTenant = sessionStorage.getItem('currentTenant');
        const storedTenantProjectPaths = JSON.parse(sessionStorage.getItem('userProjectPaths'));
        const storedUserData = JSON.parse(sessionStorage.getItem('userData'));

        // Check if a tenant is selected, if not redirect to tenant selection
        if (!storedTenant && !currentTenant) {
            console.log("No Tenant selected, redirecting to tenant selection.");
            navigate('/tenant-selection', { replace: true });
        } else {
            console.log('Tenant is valid');

            // Update the context if only storedUserTenants are present
            if (storedTenant && !currentTenant) {
                setCurrentTenant(storedTenant);
            }

            // Check if the project lists are valid (not null and length > 0)
            const validStoredTenantProjectPaths = storedTenantProjectPaths && storedTenantProjectPaths.length > 0 &&
            storedTenantProjectPaths.some(path => path.includes(storedTenant || currentTenant));
            const validUserProjects = userProjects && userProjects.length > 0 &&
            userProjects.some(path => path.includes(storedTenant || currentTenant));;

            console.log('Project Paths conditions:', { validStoredTenantProjectPaths, validUserProjects });

            if (!validUserProjects && !validStoredTenantProjectPaths) {
                console.log('Both project lists are invalid, loading user data...');
                getUserData(storedTenant || currentTenant);
            } else if (validStoredTenantProjectPaths && !validUserProjects) {
                console.log('Valid project data cached, setting variables.');
                setUserProjects(storedTenantProjectPaths);
                setUserData(storedUserData);
            } else {
                console.log('Valid project data available, no need to reload.');
            }

            setIsLoggedIn(true);
                
        }
        
    };

    useEffect(() => {
    
        if (!isAuthorized) {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code) {
                console.log('Received authorization code:', code);
                handleOAuthCallback(code);
            }
        }     
    }, [isAuthorized]);

    useEffect(() => {
    
        if (!isAuthorized){
            console.log('Not Authorized!');
            loadAuthAndTenant();
        }
    
        if (isAuthorized && !isLoggedIn) {
            console.log('Authorized, Not Logged-in!');
            //if (isModuleAvailable(moduleName)) {
            loadUserData();
            //}
        } else if (isAuthorized && isLoggedIn){
            console.log('Authorized, Logged-in!');
            //return children; // Render children when everything is validated
        } 
    
    }, [isLoggedIn, isAuthorized, currentTenant]);
    

    // Handle what to render while waiting for checks to complete
    if (isLoggedIn && currentTenant && userProjects) {
        return children; // Render children when everything is validated
    }
};

export default ProtectedRoute;
