import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import { SnackbarProvider } from 'notistack';

/* import { AppContextProvider } from './contexts/AppContext'; */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SnackbarProvider 
    maxSnack={3}
    sx={{background:'transparent'}}
    >
    <BrowserRouter>
        <AuthProvider>
          <AppProvider>
            
              <App />

          </AppProvider>
        </AuthProvider>
     </BrowserRouter>
  </SnackbarProvider>
     
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
