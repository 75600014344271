import React, { /* useState,  */useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Box, /* TextField,  */Button, /* Typography,  */Container, CssBaseline } from '@mui/material';

const LoginPage = () => {
  const { login, isLoggedIn } = useAuth();
  const navigate = useNavigate();
/*   const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); */

  useEffect(() => {
    // Apply the background style to the body tag
    document.body.style.backgroundImage = 'url(/background.webp)';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center center';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.margin = '0';
    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';

    // Cleanup function to reset the body styles when the component unmounts
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.overflow = '';
    };
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    login();
    if (isLoggedIn/*( username, password )*/) {
      //navigate('/');
      // Redirect to the dashboard upon successful login
      navigate('/');
    } /* else {
      alert('Invalid credentials');
    } */
  };

  return (
    <Container component="main" maxWidth="xs" sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // Additional styles to ensure the form stands out from the background
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: 3,
      borderRadius: 1,
      boxShadow: 3,
      marginTop: 8,
    }}>
      <CssBaseline />
      <Box component="img" src="/nukleus.png" alt="nukleus" sx={{ width: 160, marginBottom: 2 }} />
      <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
        {/* <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Anmelden
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;
