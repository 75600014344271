import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


// DeleteConfirmationModal component
export default function DeleteConfirmationModal ({ open, onClose, onConfirm, row }) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Löschbestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sind Sie sicher, dass Sie diesen Artikel löschen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => onConfirm(row)} color="primary" autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    );
  };