import React from 'react';
import { IconButton, Breadcrumbs, Link, Typography, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const NavigationBreadcrumbs = ({ onBackClick, projectName, tooltipText }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: 16 }}>
      <IconButton onClick={onBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
        <Link color="inherit" href="#" onClick={onBackClick}>
          Liste
        </Link>
        <Typography color="textPrimary" style={{ fontWeight: 'bold', fontSize: '1.45rem' }}>
          {projectName}
        </Typography>
      </Breadcrumbs>
      <Tooltip title={tooltipText || "Erstellen Sie ein Projekt um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden."}>
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default NavigationBreadcrumbs;
