import React from 'react';
import { Button, Link, List, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import config from '../config';

const getUrlForPath = (project_path, doc_id) => {
  if (project_path !== '' && project_path.length > 1 && /\//.test(project_path)) {
      let modified_path = project_path.substring(1)
      let last_slash_pos = modified_path.lastIndexOf('/')
      if (last_slash_pos !== -1){
        modified_path = modified_path.substring(0, last_slash_pos);
      }
      return config.envs[config.active_env].domain + config.nextcloud_path + modified_path + '&openfile=' + doc_id;
  } else {
      return "";
  }
};

const TaskListView = ({ rows }) => {
  const columns = [
    { field: 'text', headerName: 'Aufgabe', flex:2, },
    { field: 'assignee', headerName: 'Verantwortlicher', flex:0.5,},
    { field: 'due_date', headerName: 'Fälligkeitsdatum', flex:0.5, },
    { field: 'documents', headerName: 'Im Dokument gefunden', flex:1.5, 
      renderCell: (params) => {
        return(   
          <List variant="body2">
            {params.row.documents.map((doc, index) => (   
                  <Link key={index} sx={{padding:1}} target="_blank" href={getUrlForPath(doc.path, doc.document_id)} rel="noreferrer">{doc.path}</Link>                                                 
              ))}
          </List>            
        )
      },
      valueGetter: (params) => {
        // This prepares the 'documents' field for export by creating a comma-separated list of document paths
        return params.row.documents.map(doc => getUrlForPath(doc.path, doc.document_id)).join(', ');
      }
    },
  ];

  // Using row index as a unique identifier
  const getRowId = React.useCallback((row, index) => `${row.text}-${index}`, []);

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <DataGridPro
        getRowId={getRowId}
        autoHeight
        rows={rows}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
        }}
        pagination
        pageSizeOptions={config.ui_settings.pagination_page_size_options}
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
        disableRowSelectionOnClick
        disableMultipleSelection
        sx={{ '& .MuiDataGrid-columnHeaders': { backgroundColor: 'lightgrey' } }}
      />
    </div>
  );
};

export default TaskListView;
