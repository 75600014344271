import React from 'react';
import './App.css';
import {Route, Routes, Navigate } from "react-router-dom";
import LoginPage from './pages/LoginPage';
import Search from './pages/DocumentSearch';
import QA from './pages/QA';
import ProjectsPage from './pages/Projects';
import ReportPage from './pages/Reports';
import { LicenseInfo } from '@mui/x-license-pro';
import config from './config';
import ProtectedRoute from './components/ProtectedRoute';
import Navigation from './components/Navigation';
import { useAuth } from './contexts/AuthContext';
import { useAppContext } from './contexts/AppContext';
import ProgressScreen from './pages/ProgressScreen';
import MobileMessagePage from './pages/MobileMessagePage';
import useIsSmallScreen from './hooks/useIsSmallScreen';
import ACList from './components/ACList';

import ReportInstancesGrid from './components/ReportInstances';
import ReportTemplates from './components/ReportTemplates';
import ReportView from './components/ReportView';
import TenantSelection from './components/TenantSelection';
import NavigationListener from './components/NavigationListener';
/* import ProjectsDetailPage from './components/ProjectsDetailPage'; */

// TODO: set license in env var: process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY
LicenseInfo.setLicenseKey(config.mui_license);

// Component to conditionally render NavDrawer
const ProtectedNavigation = () => {
  const { isLoggedIn, currentTenant } = useAuth(); // Use the authentication context
  if (isLoggedIn && currentTenant) {
      return <Navigation />
  } else {
    return null; // Don't render NavDrawer if not logged in
  }
};

const ProtectedTenantSelection = () => {
  const { isAuthorized } = useAuth(); // Use the authentication context
  if (isAuthorized) {
      return <TenantSelection />
  } else {
    return null; // Don't render NavDrawer if not logged in
  }
};

const App = () => {
  const { isLoadingUserData, userDataError } = useAppContext(); 
  const isSmallScreen = useIsSmallScreen();

  if (isLoadingUserData) {
    // Display progress screen while userData is loading
    return <ProgressScreen />;
  } 
  
  if (userDataError) {
    // Redirect to login page if there was an error fetching userData
    return <Navigate to="/login" replace />;
  }
  
  if (isSmallScreen) {
    return <MobileMessagePage />;
  }

  return (
    <div className="App">
            <ProtectedNavigation />
            <NavigationListener />
            <Routes>
              <Route path="/login" element={<LoginPage />} /> 
              <Route path="/tenant-selection" element={

                    <ProtectedTenantSelection />
                  
              } />
              <Route path="/" element={
                  <ProtectedRoute moduleName="Suche">
                    <Search />
                  </ProtectedRoute>
                } 
              />
              <Route path="/q-a" element={
                  <ProtectedRoute moduleName="Q&A">
                    <QA />
                  </ProtectedRoute>
                } 
              />
              <Route path="/projects" element={
                  <ProtectedRoute moduleName="Projekte">
                    <ProjectsPage />
                  </ProtectedRoute>
                } 
              />
              {/* <Route path="projects/:path" element={
                <ProtectedRoute>
                  <ProjectsDetailPage />
                </ProtectedRoute>
              } /> */}
              <Route path="/reports" element={
                  <ProtectedRoute moduleName="Berichte">
                    <ReportPage />
                  </ProtectedRoute>
                } 
              >
                <Route path="criteria" element={
                  <ProtectedRoute moduleName="Akzeptanzkriterien">
                    <ACList />
                  </ProtectedRoute>
                } />
                <Route path="templates" element={
                  <ProtectedRoute moduleName="Berichtdefinitionen">
                    <ReportTemplates />
                  </ProtectedRoute>
                } />
                <Route path="instances" element={
                  <ProtectedRoute moduleName="Berichtergebnisse">
                    <ReportInstancesGrid />
                  </ProtectedRoute>
                } />
                <Route path="instances/:id" element={
                  <ProtectedRoute moduleName="Berichtergebnisse">
                    <ReportView />
                  </ProtectedRoute>
                } />
              </Route>
              {/* Catch-all route to redirect to root path */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
    </div>
  );

}

export default App;
