import React, { useState } from 'react';
import {
  Typography, Grid, Card, CardContent, CardHeader, MenuItem, Select, Divider, Button
} from '@mui/material';
import TaskList from './ProjectsTaskList';
import TopicList from './ProjectsTopicList';
import ProjektStatus from './ProjektStatus';
import NavigationBreadcrumbs from './NavigationBreadcrumbs';
import ProjectStrategyRAG from './ProjectStrategyRAG';
import ProjectsLinkToFiles from './ProjectsLinkToFiles';
import ProtocolModal from './ProtocolModal';

const ProjectDetailView = ({ data, onBackClick, extractedData, calculatedData, summary, mockTopics }) => {
  const [selectedView, setSelectedView] = useState('Taktik');
  const [modalOpen, setModalOpen] = useState(false);

  const handleViewChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const renderField = (label, value) => (
    <Typography variant="body2" style={{ margin: '8px 0' }}>
      <strong>{label}: </strong>{value}
    </Typography>
  );

  const projectProgressPercentage = (calculatedData) => {
    if (calculatedData && calculatedData.phase_completion_percentage !== undefined) {
      if (calculatedData.phase_completion_percentage === 0) {
        return '0%';
      } else {
        return calculatedData.phase_completion_percentage.toFixed(2) + '%';
      }
    } else {
      return 'Unbekannt';
    }
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="stretch">
        <NavigationBreadcrumbs onBackClick={onBackClick} projectName={data.name} tooltipText="Hier erhalten Sie eine Projekt Überblick." />
        <Select
          value={selectedView}
          onChange={handleViewChange}
          sx={{ float: 'right', margin: 2 }}
          size="small"
        >
          <MenuItem value="Taktik">Taktik</MenuItem>
          <MenuItem value="Strategie">Strategie</MenuItem>
          <MenuItem value="Operativ">Operativ</MenuItem>
        </Select>
      </Grid>

      <div style={{ padding: '0px' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', overflow: 'visible' }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
              <CardHeader title="Projektinformationen" sx={{ textAlign: 'left', paddingBottom: 0 }} />
              <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
                {renderField("Name", data.name)}
                {renderField("Datenablage Pfad", <ProjectsLinkToFiles path={data.path} />)}
                {renderField("SOLL Phase", data.predicted_phase)}
                {renderField("SOLL Projektfortschritt", projectProgressPercentage(calculatedData))}
                {renderField("Zielkategorie", data.project_category)}
                {renderField("Nutzungskategorie", data.project_class)}
                {renderField("Profil", data.profile)}
                {renderField("Startdatum", data.start_date.toString())}
                {renderField("Enddatum", data.end_date.toString())}

                <Divider />
                <Typography variant="h6" sx={{ textAlign: 'left', margin: '16px' }}>
                  Neues Protokoll
                </Typography>
                <Typography variant="subtitle1" sx={{ textAlign: 'left', margin: '0 16px 16px 16px' }}>
                  Erstellen Sie ein neues Protokoll für dieses Projekt. Sie können dieses schreiben oder ein Gespräch aufzeichnen und dieses transkribieren.
                </Typography>
                <Button variant="contained" onClick={handleModalOpen} sx={{ margin: '16px' }}>
                  Neues Protokoll erfassen
</Button>
              <Divider />
              
              </CardContent>

              <ProjektStatus calculatedData={calculatedData} extractedData={extractedData} summary={summary} />
              
            </Card>
            
          </Grid>
          <Grid item xs={9}>
            {selectedView === 'Strategie' && <ProjectStrategyRAG projectData={data} />}
            {selectedView === 'Taktik' && (
              <>
                <TopicList projectName={data.name} />
              </>
            )}
            {selectedView === 'Operativ' && (
              <>
                <TaskList dataGridRows={extractedData} />
              </>
            )}
          </Grid>
        </Grid>
      </div>

      <ProtocolModal
        open={modalOpen}
        onClose={handleModalClose}
      />
    </React.Fragment>
  );
};

export default ProjectDetailView;
