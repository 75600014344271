import React, { useState } from 'react';
import { 
  MenuItem, Button, TextField, Tooltip,
  Grid, Select, InputLabel, FormControl, 
  IconButton, Breadcrumbs, Link, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import config from '../config';
import { useAppContext } from '../contexts/AppContext';
import NavigationBreadcrumbs from './NavigationBreadcrumbs';

const ProjectDetailEdit = ({ data,  onSaveClick, onBackClick, action }) => {
  const convertToDate = (dateString) => {
    const parsedDate = new Date(dateString); 
    return isNaN(parsedDate) ? null : parsedDate;
  };

  const [editData, setEditData] = useState({
    ...data,
    start_date: convertToDate(data.start_date),
    end_date: convertToDate(data.end_date),
  })
  
  const [tempStartDate, setTempStartDate] = useState(editData.start_date);
  const [tempEndDate, setTempEndDate] = useState(editData.end_date);
  const [ifValidDates, setIfValidDates] = useState(false);
  const [ifValidName, setIfValidName] = useState(true);
  const { userProjects } = useAppContext();

  const isDateValid = (startDate, endDate) => {
    if (startDate && endDate && isNaN(startDate.getTime()) === false && isNaN(endDate.getTime()) === false && endDate <= startDate) {
      return false; 
    } 
    return true;
  };

  const isValidName = (name) => {
    const regex = /^[a-zA-Z0-9äöüÄÖÜß\s]+$/;
    const sanitizeName = (name) => {
      return name.trim().replace(/\s+/g, '_').toLowerCase();
    };
    const sanitizedInputName = sanitizeName(name);
    const projectNames = userProjects.map(path => {
      const projectName = path.split('/').pop();
      return sanitizeName(projectName);
    });
    
    return {
      isValid: regex.test(name) && !projectNames.includes(sanitizedInputName),
      isUnique: !projectNames.includes(sanitizedInputName),
      matchesRegex: regex.test(name),
    };
  };

  const handleDateChange = (field, value) => {
    let start_date_to_validate = null;
    let end_date_to_validate = null;
    if (field === 'start_date'){
      start_date_to_validate = new Date(value);
      end_date_to_validate = new Date(tempEndDate);
    } 
    
    if (field === 'end_date') {
      start_date_to_validate = new Date(tempStartDate);
      end_date_to_validate = new Date(value);
    }

    if (isDateValid(start_date_to_validate, end_date_to_validate)) {
      setIfValidDates(true);
    } else {
      setIfValidDates(false);
      alert('Das Enddatum muss nach dem Startdatum liegen.');
    }

    setEditData((prev) => ({ ...prev, [field]: value }));
  };

  const handleEditChange = (field, value) => {
    if (field === 'name') {
      if(!userProjects) {
        alert('Die Projektliste wurde nicht korrekt geladen. Bitte die Projekt Reiter nochmals laden.');
        setIfValidName(false);
        return
      } else {
        const validationResult = isValidName(value);
        if (!validationResult.isValid) {
          console.log('Invalid Name:', value)
          if (!validationResult.isUnique) {
            alert('Der gewählte Name existiert bereits. Bitte wählen Sie einen anderen Namen.');
            setIfValidName(false);
            return
          } else if (!validationResult.matchesRegex) {
            alert('Der Name enthält unzulässige Zeichen. Erlaubt sind Buchstaben, Zahlen, Leerzeichen und deutsche Umlaute.');
            setIfValidName(false);
            return
          }
        } else {
          setIfValidName(true);
        }
      }
    }

    const updatedEditData = { ...editData, [field]: value };
    if (field === 'start_date' || field === 'end_date') {
      const startDate = new Date(updatedEditData.start_date);
      const endDate = new Date(updatedEditData.end_date);

      if (startDate && endDate && isNaN(startDate.getTime()) === false && isNaN(endDate.getTime()) === false && endDate <= startDate) {
        alert('Das Enddatum muss nach dem Startdatum liegen.');
        return; 
      }
    }

    setEditData(updatedEditData);
  };

  const areRequiredFieldsPresentAndValid = (editData) => {
    const requiredFields = ['name', 'project_class', 'project_category', 'profile', 'start_date', 'end_date'];
    const areFieldsPresent = requiredFields.every(field => editData[field] !== undefined && editData[field] !== '');
    return areFieldsPresent;
  };

  const handleLocalSaveClick = () => {
    if (!editData.profile || !editData.start_date || !editData.end_date  || !editData.name || !editData.project_class || !editData.project_category) {
      alert('Please fill all required fields.');
      return;
    }
    onSaveClick(editData);
  };

  const renderDisabledField = (label, value, field, type, isDisabled) => {
    return (
      <TextField
        label={label}
        value={value}
        required
        type={type === 'number' ? 'number' : 'text' }
        inputProps={{ min: 0, max: 1, step: 0.01 }}
        onChange={(e) => handleEditChange(field, e.target.value)}
        fullWidth
        disabled={isDisabled}
      />
    );
  };
  const tooltipText = "Erstellen Sie ein Projekt um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden.";

  return (
    <React.Fragment>
      <NavigationBreadcrumbs onBackClick={onBackClick} projectName={editData.name} tooltipText={tooltipText} />

      <div style={{ padding: '20px' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            {renderDisabledField("Name", editData.name, 'name', 'text', action === 'edit')}
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel>Zielkategorie</InputLabel>
              <Select
                label="Zielkategorie"
                required
                value={editData.project_category}
                onChange={(e) => handleEditChange('project_category', e.target.value)}
              >
                {config.ui_messages.goal_category.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel>Nutzungskategorie</InputLabel>
              <Select
                label="Nutzungskategorie"
                required
                value={editData.project_class}
                onChange={(e) => handleEditChange('project_class', e.target.value)}
              >
                {config.ui_messages.usage_category.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel>Profil</InputLabel>
              <Select
                label="Profil"
                value={editData.profile}
                onChange={(e) => handleEditChange('profile', e.target.value)}
              >
                {config.ui_settings.profile_options.map((option, index) => (
                  <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <Grid item xs={2}>
              <DatePicker
                required
                label="Startdatum"
                value={tempStartDate}
                onChange={(newValue) => {
                  setTempStartDate(newValue);
                  handleDateChange('start_date', newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={2}>
              <DatePicker
                required
                label="Enddatum"
                value={tempEndDate}
                onChange={(newValue) => {
                  setTempEndDate(newValue);
                  handleDateChange('end_date', newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button 
              variant="contained" 
              color="primary" 
              disabled={!areRequiredFieldsPresentAndValid(editData) || !ifValidDates || !ifValidName}
              onClick={handleLocalSaveClick} 
              style={{ marginRight: '8px' }}>
              Vortfahren
            </Button>
            <IconButton onClick={onBackClick}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ProjectDetailEdit;