import React, { useEffect, useState } from 'react';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import reportTemplate from '../pages/reportTemplate';
import { Button } from '@mui/material';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportGenerator = (report_data) => {
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    setReportData({ ...report_data.report_data, currentDate });
  }, [report_data]);

  const generatePDF = () => {
    const htmlContent = reportTemplate(reportData);
    const pdfContent = htmlToPdfmake(htmlContent);
    const documentDefinition = { content: pdfContent };
    // Create the PDF as a Blob
    pdfMake.createPdf(documentDefinition).getBlob((blob) => {
      // Create a download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'report.pdf'; // Suggested filename for download

      // Programmatically click the link to trigger the download and force the "Save As" dialog
      link.dispatchEvent(
        new MouseEvent('click', { 
          bubbles: true, 
          cancelable: true, 
          view: window 
        })
      );

      // Clean up the object URL after the download
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
      }, 100);
    });

  };

  return (
      <Button variant="contained" color="primary" onClick={generatePDF}>
        Als PDF Speichern
      </Button>
  );
};

export default ReportGenerator;
