import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useAppContext } from '../contexts/AppContext';

export default function SimpleSnackbar() {
/*   const [open, setOpen] = React.useState(false); */

  const { qaFeedbackMessage, setQAFeedbackMessage } = useAppContext();  

/*   const handleClick = () => {
    setOpen(true);
  }; */

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setQAFeedbackMessage("");
    /* setOpen(false); */
  };

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      {/* <Button onChange={handleClick}>Open simple snackbar</Button> */}
      <Snackbar
        open={qaFeedbackMessage.length > 1}/* {open} */
        autoHideDuration={6000}
        onClose={handleClose}
        message={qaFeedbackMessage}
        action={action}
      />
    </div>
  );
}