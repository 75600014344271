import React, { useState } from 'react';
import {Grid, Typography, Chip, Card, CardHeader, CardContent } from '@mui/material';


const ViewDetailReportTemplate = ({ editData }) => {

    const renderViewField = (label, value) => {
        return (
            <Typography variant="body1" style={{ margin: '8px 0' }}>
            <strong>{label}: </strong>{value}
            </Typography>
        );
    };

    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                {/* Left Column for Fields View */}  
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%',  width: '100%' }}>
                        <CardHeader 
                        title={editData.title} 
                        sx={{ textAlign: 'left', paddingBottom: 0 }}
                        />
                        <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
                        {renderViewField("Beschreibung", editData.description, 'description')}
                        <Chip label={editData.status} color={editData.status === 'published' ? 'success' : 'warning'} />
                        {renderViewField("Projekt", editData.project.name, 'project')}
                        {renderViewField("Startdatum", editData.start_date.toString(), 'start_date')}
                        {renderViewField("Enddatum", editData.end_date.toString(), 'end_date')}
                        // Check if `recurrence` and its nested properties exist before rendering the fields
                        {editData.recurrence && editData.recurrence.frequency && editData.recurrence.frequency.value &&
                            renderViewField("Wiederholungsfrequenz", editData.recurrence.frequency.value, 'frequency', true)
                        }

                        {editData.recurrence && editData.recurrence.interval && editData.recurrence.interval.value &&
                            renderViewField("Interval", editData.recurrence.interval.value, 'interval', true)
                        }

                        {editData.recurrence && editData.recurrence.weekday_of_the_month && editData.recurrence.weekday_of_the_month.value &&
                            editData.recurrence.weekday_of_the_month.value.occurrence && editData.recurrence.weekday_of_the_month.value.weekday &&
                            renderViewField("Tag", editData.recurrence.weekday_of_the_month.value.occurrence + editData.recurrence.weekday_of_the_month.value.weekday, 'weekday_of_the_month', true)
                        }

                        
                        {/*Wissens*/}
                        <Typography variant='h6'>Wissens</Typography>
                        {editData.reference_knowledge.map((field, index) => (
                            field.title
                        ))}

                        <Typography variant='h6'>Vorlagen</Typography>
                        {editData.reference_layouts.map((field, index) => (
                            field.title
                        ))}

                        <Typography variant='h6'>Definitionen</Typography>
                        {editData.definitions.map((field, index) => (
                            field
                        ))}

                        <Typography variant='h6'>Regeln</Typography>
                        {editData.rules.map((field, index) => (
                            field
                        ))}

                        <Typography variant='h6'>Aktionen</Typography>
                        {editData.actions.map((field, index) => (
                            field.title
                        ))}

                        <Typography variant='h6'>Shritte</Typography>
                        {editData.instructions.map((field, index) => (
                            field
                        ))}

                        <Typography variant='h6'>Beispiele</Typography>
                        {editData.examples.map((field, index) => (
                            field
                        ))}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default ViewDetailReportTemplate;