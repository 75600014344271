import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { 
  Card, CardContent, CardHeader, Link, List,
  Chip, Typography, IconButton, TextField, Button,  
  Tooltip } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import config from '../config';

const TaskList = ({ dataGridRows }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
      // Simulating a fetch-like operation
      setRows(dataGridRows);
    }, [dataGridRows]);
  
    const [expanded, setExpanded] = useState(true); // Default state is collapsed
    const handleExpandClick = () => {
        setExpanded(!expanded);
      };

    // Add 'status' attribute to each row item
    /* const rowsWithStatus = dataGridRows.map(item => ({
      ...item,
      status: 'offen' 
    })); */

    const isDelayedStatus = ( status ) => {
      if (status === true) {
        return (
          "vergangene"
        )
      } else if (status === null) {
        return (
          "unbekannt"
        )
      } else {
        return (
          "bevorstehende"
        )
      }
    };

    const getUrlForPath = (project_path, doc_id) => {
      if (project_path !== '' && project_path.length > 1 && /\//.test(project_path)) {
          let modified_path = project_path.substring(1)
          let last_slash_pos = modified_path.lastIndexOf('/')
          if (last_slash_pos !== -1){
            modified_path = modified_path.substring(0, last_slash_pos);
          }
          return config.envs[config.active_env].domain + config.nextcloud_path + modified_path + '&openfile=' + doc_id;
      } else {
          return "";
      }
    };

    const dataGridColumns = [
     /*  { field: 'id', headerName: 'ID', width: 90 }, */
      { field: 'text', headerName: 'Aufgabe', flex:2, },
      { field: 'assignee', headerName: 'Verantwortlicher', flex:0.5,},
      { field: 'due_date', headerName: 'Fälligkeitsdatum', flex:0.5, },
      { field: 'documents', headerName: 'Im Dokument gefunden', flex:1.5, 
        renderCell: (params) => {
         return(   
            <List variant="body2">
              {params.row.documents.map((doc, index) => (   
                  <Link key={index} sx={{padding:1}} target="_blank" href={getUrlForPath(doc.path, doc.document_id)} rel="noreferrer">{doc.path}</Link>                                                 
              ))}
            </List>            
          )
        }
      },
      { field: 'status', headerName: 'Status', flex:0.5,
        renderCell: (params) => {
         return(   
          
          <Chip label={isDelayedStatus(params.row.status)} color={params.row.status === true ? 'default' : (params.row.status === null ? 'warning' : 'success')} /> 
                             
          )
        },
        valueGetter: (params) => isDelayedStatus(params.row.status),
      }
    ];

    // Using row index as a unique identifier
    const getRowId = React.useCallback((row, index) => `${row.text}-${index}`, []);

  
    return (
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardHeader 
                action={
                <IconButton onClick={handleExpandClick} sx={{ marginLeft: 'auto', padding: 0 }}>
                
                </IconButton>
            }
            title={
  
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleExpandClick}>
                        <AutoAwesomeIcon color="secondary" sx={{ verticalAlign: 'middle', marginRight: 1, marginBottom:2 }} />
                        <Tooltip title={config.ui_messages.ai_disclaimer}>
                            <IconButton sx={{ padding: 0, marginRight: 2 }}> 
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        Wesentliche Aufgaben
                    </Typography>


            } 
            sx={{ textAlign: 'left', paddingBottom: 0 }}
        />
        {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
            <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
            <DataGridPro 
                rowHeight={46} // Default is 52
                /* headerHeight={40} */ // Default is 56
                getRowId={getRowId}
                initialState={{
                pagination: { paginationModel: { pageSize: 7 /* config.ui_settings.pagination_page_size */ } },
                }}
                width='100%' 
                checkboxSelection
                rows={rows} 
                columns={dataGridColumns} 
                pagination
                pageSizeOptions={config.ui_settings.pagination_page_size_options}
            />
            <TextField
                fullWidth
                disabled
                label="Frage stellen"
                variant="outlined"
                value={''}
                onChange={''}
                sx={{ marginTop: 2, marginBottom: 2 }}
            />
            <Button
                variant="contained"
                disabled
                color="primary"
                onClick={''}
                sx={{ float: 'right' }}
            >
                Frage stellen
            </Button>
            <TextField
                fullWidth
                disabled
                label="Antwort"
                multiline
                rows={4}
                variant="outlined"
                value={''}
                InputProps={{
                    readOnly: true,
                }}
                sx={{ marginTop: 2 }}
            />
            </CardContent>  
     {/*    </Collapse> */}
        </Card>
      
    )  
  }


  export default TaskList;