import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearProgressIndeterminate(props) {
    const LinearIndeterminate = () => {
        if(props.isLoading) {
            return (
                <LinearProgress />
            );
        }
    }

    return (
        <LinearIndeterminate/>
    );
}