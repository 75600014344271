import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import '../App.css'; // Assuming your CSS file is named App.css

const getProgressMessage = () => {
  const messages = [
    "Orchestrierung einer Konstellation Ihrer Informationen... Ihre Geduld wird geschätzt, während wir die Sterne ausrichten.",
    "Zusammenbau der Elemente Ihres digitalen Reichs. Ein Moment der Geduld führt zu einer Ewigkeit der Ordnung.",
    "Kuratiert Ihre persönliche Datengalerie... Bitte warten Sie, während wir die letzten Handgriffe an Ihrem Meisterwerk vornehmen.",
    "Pflege des Gartens Ihrer Bytes und Bits. Beobachten Sie, wie wir Daten sorgfältig und präzise zum Blühen bringen.",
    "Zusammensetzen des Puzzles Ihrer virtuellen Identität. Jedes Bit ist ein Hinweis auf das größere Bild.",
    "Meißeln an den Rohdaten, um Ihre digitale Erfahrung zu gestalten. Die Enthüllung ist nur noch einen Augenblick entfernt.",
    "Weben der Fäden Ihres digitalen Stoffs... Ihr Online-Gewebe erwacht zum Leben, Stich für Stich.",
    "Entschlüsselung der DNA Ihres digitalen Lebens... Staunen Sie, wie wir den genetischen Code Ihrer Daten sichtbar machen.",
    "Durchforsten der Annalen Ihres digitalen Fußabdrucks. Geschichte wird geschrieben, Byte für Byte.",
    "Dirigieren eines Orchesters von Algorithmen, um die Symphonie Ihrer Daten zu spielen. Das Konzert beginnt in Kürze.",
    "Brauen die perfekte Mischung Ihrer Daten. Genießen Sie die Vorfreude wie eine feine Tasse, die auf ihren ersten Schluck wartet.",
    "Polieren die Linsen Ihrer digitalen Welt. Klarheit ist nur einen Moment der Geduld entfernt.",
    "Schüren die Flammen der Daten-Schmiede. Ihr digitales Schicksal wird mit Präzision und Geschick geformt.",
    "Zeichnen den Kurs durch Ihr Meer an Daten. Machen Sie sich bereit zur Entdeckung – es dauert nur noch einen Moment.",
    "Nutzen die Winde der Daten, um uns dem Verständnis zu nähern. Die Reise zum Wissen beginnt gleich.",
    "Bereiten die Bühne für ein Ballett der Bytes vor. Ihre Daten werden gleich vor Ihren Augen tanzen.",
    "Stimmen die Instrumente für ein Konzert der Konnektivität. Die Aufführung Ihres Netzwerks beginnt bald.",
    "Rühren im Kessel der Kreativität. Ein Quäntchen Geduld, eine Prise Daten und Ihr Trank wird gleich fertig sein.",
    "Beschwören die Geister der Synthese. Ihre Datenelemente verschmelzen zu einem harmonischen Ensemble.",
    "Beschwören den Wandteppich Ihrer digitalen Existenz. Der Webstuhl der Logik ist am Werk und verwebt Ihre Bytes.",
    "Konstruieren den Bauplan Ihrer virtuellen Landschaft. Ihr digitaler Bereich nimmt Gestalt an.",
    "Navigieren durch das Labyrinth Ihrer Alt-Daten. Bald wird der Weg klar und Ihre Schätze offenbart.",
    "Kalibrieren den Kompass Ihrer rechnerischen Suche. Der wahre Norden findet sich in den Details Ihrer Daten.",
    "Sieben nach Gold im Fluss Ihrer digitalen Vermögenswerte. Nuggets des Wissens sind fast schon greifbar.",
    "Synthetisieren die Elemente Ihrer Cyber-Sphäre. Die Alchemie der Information ist im Gange."
  ];
  
  // Randomly select a message
  return messages[Math.floor(Math.random() * messages.length)];
}

const ProgressScreen = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      backgroundImage: 'url("/background.webp")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <Card 
      sx={{ 
        minWidth: '50vw', // Minimum width to 3/4 of the viewport width
        height: '50vh', // Height set to 3/4 of the viewport height
        backgroundColor: 'rgba(255, 255, 255, 0.8)', 
        overflow: 'visible', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}
    >
      <CardHeader
      sx={{ '& .MuiCardHeader-content': { alignSelf: 'center' } }}
      title={
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
          <img
            src="/progress_icon.webp"
            alt="Loading"
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              animation: 'bounce 1s infinite',
            }}
          />
        </div>
      }
      titleTypographyProps={{ variant: 'body1', component: 'div' }}
    />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Typography with controlled width */}
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          maxWidth: '60%', // Control the width to encourage text wrapping
        }}
      >
        {getProgressMessage()}
      </Typography>
    </CardContent>
    </Card>
  </Box>
);

export default ProgressScreen;
