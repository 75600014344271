import React, { useState } from 'react';
import { Box, Tooltip, Typography, Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Import the external link icon

import dayjs from 'dayjs';

// Function to calculate the color based on average score
const getColorFromScore = (averageScore) => {
  if (averageScore < 0.66) return 'red'; // Close to 0
  if (averageScore >= 0.66 && averageScore < 1.33) return 'yellow'; // Around 1
  return 'green'; // Close to 2
};

const ProjectOverviewHorizontalTimeline = ({ reports }) => {
  const [activeReport, setActiveReport] = useState(null);

  // Ensure reports is an array
  const validReports = Array.isArray(reports) ? reports : [];

  // Group reports by day and calculate summary data
  const groupedReports = validReports.reduce((acc, report) => {
    const dateKey = dayjs(report.creation_date).format('YYYY-MM-DD'); // Group by day
    if (!acc[dateKey]) {
      acc[dateKey] = { reports: [], totalScore: 0, count: 0 };
    }
    acc[dateKey].reports.push(report);
    acc[dateKey].totalScore = (acc[dateKey].totalScore * (acc[dateKey].count - 1) + report.scores.reduce((p, c) => p + c.averageScore, 0) / report.scores.length) / acc[dateKey].count;
    acc[dateKey].count += 1;
    return acc;
  }, {});

  // Sort the dates
  const sortedDates = Object.keys(groupedReports).sort((a, b) => dayjs(a).diff(dayjs(b)));

  // Get today's date and the date 3 weeks ago
  const today = dayjs();
  const threeWeeksAgo = dayjs().subtract(3, 'week');

  // Function to calculate the position of the report on the timeline (relative to the 3-week range)
  const getCirclePosition = (reportTimestamp, totalDays) => {
    const startTime = threeWeeksAgo.startOf('day').valueOf();
    const reportTime = dayjs(reportTimestamp).startOf('day').valueOf();
  
    // Calculate which day it is in the 21-day range (from 0 to 21)
    const dayNumber = Math.round((reportTime - startTime) / (1000 * 60 * 60 * 24)); // Convert to days
    
    // Calculate the percentage position based on the total number of days
    const percentagePosition = (dayNumber / totalDays) * 100;
  
    // Return the percentage position directly (no need to adjust by half the dot size)
    return `${percentagePosition}%`;
  };
  
  const totalDays = 21; // 3 weeks = 21 days

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2, width: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '60px', // Increased height to make room for larger dots
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Horizontal line connecting the dots */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            height: '2px',
            backgroundColor: 'black', // Horizontal line color
          }}
        />

        {/* Render vertical lines for each day within the 3-week window */}
        {[...Array(22)].map((_, index) => {
          const dayPosition = (index / 21) * 100; // Calculate position for each day
          const currentDate = threeWeeksAgo.add(index, 'day'); // Add index as days to threeWeeksAgo

          // Check if it's Monday
          const isMonday = currentDate.day() === 1;

          return (
            <React.Fragment key={index}>
                <Box
                key={index}
                sx={{
                    position: 'absolute',
                    left: `${dayPosition}%`,
                    top: '25%',
                    bottom: '25%',
                    width: '1px',
                    color: 'gray',
                    backgroundColor: '#BDBDBD', // Vertical line color for each day
                }}
                />
                {isMonday && (
                    <Typography
                    sx={{
                        position: 'absolute',
                        bottom: '-25px',
                        left: `${dayPosition}%`,
                        color: 'textSecondary',
                    }}
                    >
                    {currentDate.format('DD MMM')}
                    </Typography>
                )}
            </React.Fragment>
            
          );
        })}

       

        {/* Render dots for each day within the 3-week window */}
        {sortedDates.map((date) => {
          const reportDate = dayjs(date);
          if (reportDate.isBefore(threeWeeksAgo) || reportDate.isAfter(today)) {
            return null; // Skip reports outside the 3-week range
          }

          const dayReports = groupedReports[date].reports;
          const totalReports = groupedReports[date].count;
          const averageScore = groupedReports[date].totalScore / totalReports;
          const dotSize = 10 + totalReports * 5; // Size increases with the number of reports
          const dotColor = getColorFromScore(averageScore);

          return (
            <React.Fragment key={date}>
              {/* Dot for the day */}
              <Tooltip
                title={
                    <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px', color: 'black' }}>
                      {dayReports.map((report) => (
                        <Box key={report.id} sx={{ mb: 1 }}>
                          {/* Title with Link and External Button */}
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Button
                                sx={{ padding: 1 }} // Optional: Adjust padding for the button
                                variant="outlined" // Choose a variant, e.g., contained, outlined, etc.
                                endIcon={<OpenInNewIcon />} // Add the OpenInNewIcon to the right of the text
                                href={`${window.location.origin}/reports/instances/${report.name}?project=${report.project}&creation_date=${report.creation_date}`} // Link to the report
                                /* target="_blank" // Opens in a new tab */
                                rel="noreferrer" // For security
                                >
                                {report.name} {/* Display report name */}
                            </Button>
                          </Box>
                          
                          {/* Date Information */}
                          <Typography variant="body2" sx={{ color: 'black' }}>
                            Date: {dayjs(report.creation_date).format('dd DD, HH:mm')}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                arrow
              >
                <Box
                  sx={{
                    position: 'absolute',
                        left: `calc(${getCirclePosition(reportDate, totalDays)} - ${dotSize / 2.2}px)`, // Align dot with the vertical line
                        /* top: '50%', */  // Align dots at the midpoint of the line
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    width: `${dotSize}px`,
                    height: `${dotSize}px`,
                    backgroundColor: dotColor,
                    border: '2px solid black', // Black border around the dot
                    borderRadius: '50%',
                    transform: activeReport === date ? 'scale(1.2)' : 'scale(1)',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.2)',
                    },
                  }}
                  onMouseEnter={() => setActiveReport(date)}
                  onMouseLeave={() => setActiveReport(null)}
                />
              </Tooltip>
            </React.Fragment>
          );
        })}

        {/* Render the first date marker */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: '2px',
            backgroundColor: 'black', // Line for the first date
          }}
        />

        {/* Render the current date marker */}
        <Box
          sx={{
            position: 'absolute',
            left: '100%',
            top: 0,
            bottom: 0,
            width: '2px',
            backgroundColor: 'red',
          }}
        />

        {/* Labels for start and end of the timeline */}
        <Typography
          sx={{
            position: 'absolute',
            bottom: '-25px',
            left: 0,
            color: 'textSecondary',
          }}
        >
          {threeWeeksAgo.format('DD MMM')}
        </Typography>
        <Typography
          sx={{
            position: 'absolute',
            bottom: '-25px',
            right: 0,
            color: 'textSecondary',
          }}
        >
          Heute
        </Typography>
      </Box>
    </Box>
  );
};

export default ProjectOverviewHorizontalTimeline;
