// Result List
import React from 'react';
import ProjectsGrid from '../components/ProjectsGrid';
import { styled } from '@mui/material/styles';
import { useAppContext } from '../contexts/AppContext';
import DrawerHeader from '../components/NavigationHeader';
import config from '../config';
import { LinearProgress } from '@mui/material';
const drawerWidth = config.ui_settings.drawer_width;//240;

export default function ProjectsPage() {
    const { drawerOpen, projectsLoading } = useAppContext();  

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
      ({ theme, drawerOpen }) => ({
        flexGrow: 1,
        padding: theme.spacing(0),
        marginLeft: `calc(${theme.spacing(8)} + 1px)`,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(drawerOpen && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: `calc(${drawerWidth}px)`,
        }),
      }),
    );

    return (

      <Main drawerOpen={drawerOpen}>
        <DrawerHeader />
        
        {!projectsLoading ? 
          <ProjectsGrid/> : 
          <LinearProgress />
        }
        
      </Main>
        
    );
}