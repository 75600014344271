import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';

const NavigationListener = () => {
  const location = useLocation();
  const { getProjectDetailList, qadocList, userProjects } = useAppContext();

  useEffect(() => {
    // Check if the current path is the instances list
    if (location.pathname === '/reports/instances') {

    }
    if (location.pathname === '/reports/templates') {

    }
    if (location.pathname === '/projects') {
      getProjectDetailList();
    }
    if (location.pathname === '/q-a') {
      qadocList();
    }
  }, [location, userProjects]);

  return null; // This component does not render anything
};

export default NavigationListener;
