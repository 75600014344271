import React from 'react';
import { Card, CardHeader, CardContent, Typography, Grid, Paper, Badge, Tooltip, IconButton } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import config from '../config';

const ProjektStatus = ({ calculatedData, extractedData, summary }) => {
  const taskStyles = {
    newTasks: { color: 'orange' },
    reoccurringTasks: { color: 'red' },
    totalCompletedTasks: { color: 'green' }
  };

  const getCalculatedValue = (calculatedData, fieldName) => {
    if (calculatedData === null) return 0
    else {
      return calculatedData[fieldName]
    }
  };

  const convertToDecimal = (number) => {
    if ( typeof number !== 'number' || number === 0) {
      return 0;
    }
    return +number.toFixed(2);
  }

  const undefinedCard = () => {
    if (extractedData && extractedData.length > 0) {
      let filtered_data = extractedData.filter(item => !item.due_date)
      let percentage_unkown = filtered_data.length / extractedData.length * 100
      return {
        label: 'Aufgaben/Themen ohne erkannte Fälligkeitsdatum.',
        value: convertToDecimal(percentage_unkown) + '%',
        style: {} 
      }
    } else {
      return null
    }
  }

  const completionCard = () => {
    if (calculatedData && calculatedData.completion_percentage !== undefined) {
      return {
        label: 'Aufgaben/Themen, deren anerkanntes Fälligkeitsdatum erreicht ist.',
        value: convertToDecimal(calculatedData.completion_percentage) + '%',
        style: {} 
      }
    } else {
      return null
    }
  };

  const fields = [
    { label: 'vergangene Aufgaben', value: getCalculatedValue(calculatedData, 'total_completed_tasks'), style: taskStyles.totalCompletedTasks },
    { label: 'bevorstehende Aufgaben', value: getCalculatedValue(calculatedData, 'new_tasks'), style: taskStyles.newTasks },
    { label: 'wiederholende Aufgaben', value: getCalculatedValue(calculatedData, 'reoccurring_tasks'), style: taskStyles.reoccurringTasks },
    { label: 'Total Definierte Aufgaben', value: getCalculatedValue(calculatedData, 'total_defined_tasks') }
  ];

  const getDate = (status_summary) => { 
    if (status_summary && status_summary.date !== undefined) return status_summary.date 
    else return null 
  };

  const getText = (status_summary) => { 
    if (status_summary && status_summary.text !== undefined) return status_summary.text 
    else return null 
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%',  width: '100%' }}>
      <CardHeader 
        title="Projektstatus" 
        sx={{ textAlign: 'left', paddingBottom: 0 }}
      />
      <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
        {summary && (
          <Paper elevation={3} sx={{ backgroundColor: '#f5f5f5', padding: 1, marginBottom: 4 }}>
            <Typography variant="body2" align="left" sx={{ marginBottom: 1 }}>
              <AutoAwesomeIcon color="secondary"/>
              <Tooltip title={config.ui_messages.ai_disclaimer}>
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              <b>Zusammenfassung</b> aus den letzten Protokoll bearbeitet am <b>{getDate(summary)}</b>:
            </Typography>
            <Typography variant="body2" align="left" sx={{ marginBottom: 1 }}>
              {getText(summary)}
            </Typography>  
          </Paper>
        )}
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={4}>
            <Grid container direction="column" spacing={2}>
              {fields.map((field, index) => (
                <Grid item key={index}>
                  <Typography variant='body2' style={{ display: 'inline-flex', alignItems: 'center', margin: '4px' }}>
                    {field.label}
                  </Typography>
                  <Badge style={{ ...field.style, display: 'inline-flex', alignItems: 'center', margin: '4px 0' }}
                    badgeContent={field.value}
                    color='primary'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <TaskAltIcon style={field.style} />
                  </Badge>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item align='center' xs={4} sx={{ height: '100%' }}>
            {completionCard() && completionCard().value !== undefined && 
              <Paper elevation={3} sx={{ 
                backgroundColor: 'white', 
                padding: 1, 
                marginBottom: 0, 
                height: '100%', 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
                <Typography variant='body' color="textSecondary" gutterBottom sx={{ padding: 1, flex: '1 0 auto' }}>
                  {completionCard().label}
                </Typography>
                <Typography variant="h3" sx={{ padding: 1, flex: '1 0 auto'}}>
                  {completionCard().value}
                </Typography>
              </Paper>
            }
          </Grid>
          <Grid item align='center' xs={4} sx={{ height: '100%' }}>
            {undefinedCard() && undefinedCard().value !== undefined && 
              <Paper elevation={3} sx={{
                backgroundColor: 'white',
                padding: 1,
                marginBottom: 0,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
                <Typography variant='body' color="textSecondary" gutterBottom sx={{ padding: 1, flex: '1 0 auto' }}>
                  {undefinedCard().label}
                </Typography>
                <Typography variant="h3" sx={{ padding: 1, flex: '1 0 auto' }}>
                  {undefinedCard().value}
                </Typography>
              </Paper>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProjektStatus;
