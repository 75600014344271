// DrawerHeader.jsx
import React from 'react';
import { styled } from '@mui/material/styles';

// Assuming you have a theme setup where the AppBar's height is defined
// Or you can set a fixed height if you know the AppBar's height
const appBarHeight = 1; // Example AppBar height, adjust as needed

const DrawerHeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  marginTop: `${appBarHeight}px`,
}));

const DrawerHeader = () => {
  return <DrawerHeaderStyled />;
};

export default DrawerHeader;
