import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, IconButton, TextField, Card, CardContent, Box, Paper, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const EditAcceptanceCriteria = ({ editData, action, handleLocalSaveClick, onBackClick }) => {
  const [localEditData, setLocalEditData] = useState({ ...editData });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setLocalEditData({ ...editData }); // Update local copy when editData changes
  }, [editData]);

  const validateFields = () => {
    if (!localEditData.name.trim() || !localEditData.description.trim() || !localEditData.prompt.trim() ||
      localEditData.return_schema.requirements.some(req => !req.name.trim() || !req.description.trim())) {
      setSnackbarMessage('Bitte füllen Sie alle erforderlichen Felder aus.');
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const showMessage = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const validateFieldLength = (value, maxLength) => {
    if (value.length > maxLength) {
      showMessage(`Die maximale Länge von ${maxLength} Zeichen wurde überschritten.`);
      return false;
    }
    return true;
  };

  const handleAddRequirement = () => {
    setLocalEditData(prevState => ({
      ...prevState,
      return_schema: {
        ...prevState.return_schema,
        requirements: [...prevState.return_schema.requirements, { name: '', description: '' }]
      }
    }));
  };

  const handleDeleteRequirement = (index) => {
    const updatedRequirements = localEditData.return_schema.requirements.filter((_, i) => i !== index);
    setLocalEditData(prevState => ({
      ...prevState,
      return_schema: { ...prevState.return_schema, requirements: updatedRequirements }
    }));
  };

  const handleRequirementChange = (index, field, value, maxLength) => {
    if (validateFieldLength(value, maxLength)) {
      const updatedRequirements = localEditData.return_schema.requirements.map((req, i) =>
        i === index ? { ...req, [field]: value } : req
      );
      setLocalEditData(prevState => ({
        ...prevState,
        return_schema: { ...prevState.return_schema, requirements: updatedRequirements }
      }));
    }
  };

  const handleFieldChange = (field, value, maxLength) => {
    if (validateFieldLength(value, maxLength)) {
      setLocalEditData(prevState => ({ ...prevState, [field]: value }));
    }
  };

  const onSave = () => {
    if (validateFields()) {
      handleLocalSaveClick(localEditData);
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ padding: 2, width: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" align="left">Akzeptanzkriterien erstellen/bearbeiten</Typography>
                  
                  <TextField
                    label="Titel"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    disabled={action !== 'create'}
                    value={localEditData.name}
                    onChange={(e) => handleFieldChange('name', e.target.value, 256)}
                  />
                  <TextField
                    label="Beschreibung"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={localEditData.description}
                    onChange={(e) => handleFieldChange('description', e.target.value, 512)}
                  />

                  <Box sx={{ marginTop: 2 }}>
                    <TextField
                      label="Anweisungen"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={13}
                      required
                      value={localEditData.prompt}
                      onChange={(e) => handleFieldChange('prompt', e.target.value, 4096)}
                    />
                  </Box>

                  <Box sx={{ padding: 2, mt: 2 }}>
                    {localEditData.return_schema.requirements.map((requirement, index) => (
                      <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="body1">Anforderung {index + 1}</Typography>
                          <IconButton 
                            sx={{ color: 'red' }} 
                            onClick={() => handleDeleteRequirement(index)}
                            disabled={localEditData.return_schema.requirements.length <= 1}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                        <TextField
                          fullWidth
                          label="Name"
                          variant="outlined"
                          margin="normal"
                          required
                          value={requirement.name}
                          onChange={(e) => handleRequirementChange(index, 'name', e.target.value, 256)}
                        />
                        <TextField
                          fullWidth
                          label="Beschreibung"
                          variant="outlined"
                          margin="normal"
                          required
                          value={requirement.description}
                          onChange={(e) => handleRequirementChange(index, 'description', e.target.value, 512)}
                        />
                      </Paper>
                    ))}
                    <Box display="flex" justifyContent="flex-start">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAddRequirement}
                      >
                        Anforderung erstellen
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={onSave} 
            style={{ margin: '8px' }}
          >
            Speichern
          </Button>
          <IconButton onClick={onBackClick}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="warning" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditAcceptanceCriteria;
