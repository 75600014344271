import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import config from '../config';

// Example of how your component might look
function LinkToFiles(props) {
    
    const getUrlForPath = (originalPath) => {
        if (originalPath !== '' && originalPath.length > 1) {
            const modifiedPath = originalPath.substring(1); // Use a different variable name
            return config.envs[config.active_env].domain + config.nextcloud_path + modifiedPath;
        } else {
            return "";
        }
    };

    const handleButtonClick = (path) => {
        // Assuming getUrlForPath returns a URL string
        const url = getUrlForPath(path);
        window.open(url, '_blank');
    };

    return (
        <Button 
        size="small" 
        sx={{ 
            padding: '6px 8px', // Adjust padding to make the button slimmer
            /* marginBottom: '20px', */
            textTransform: 'none' // Prevents text from transforming to uppercase
        }} 
        onClick={() => handleButtonClick(props.path)} 
        variant="outlined">
        <Typography variant="body2" sx={{ textTransform: 'none' }}> {/* Ensures text isn't transformed */}
            {props.path}
        </Typography>
        </Button>

    );
};

export default LinkToFiles;
