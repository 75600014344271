import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import base64 from 'base-64';
import config from '../config';

const AuthContext = createContext(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [token, setToken] = useState(null);
    const [userTenants, setTenants] = useState(null);
    const [currentTenant, setCurrentTenant] = useState(null);
    const navigate = useNavigate();
    const clientId = config.client_id;
    const redirectUri = `${window.location.origin}`;

    const login = () => {
        const authServerUrl = config.envs[config.active_env].domain + 'dex/auth';
        const responseType = 'code';
        const scope = 'openid email profile groups';
        const authUrl = `${authServerUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}`;
        window.location.href = authUrl;
    };

    const decodeJWT = (encoded_token) => {

        // Split the JWT into its three parts: Header, Payload, and Signature
        const parts = encoded_token.split('.');
        
        // Check if the JWT contains all three parts
        if (parts.length !== 3) {
          throw new Error('Invalid JWT: The token must consist of three parts.');
        }
        
        // Decode the payload from base64 URL to a JSON object
        const payload = parts[1];
        const decodedPayload = JSON.parse(window.atob(payload.replace(/-/g, '+').replace(/_/g, '/')));
        
        return decodedPayload;
    };

    const handleOAuthCallback = async (code) => {
        const tokenEndpoint = config.envs[config.active_env].domain + 'dex/token';
        const clientSecret = encodeURIComponent(config.envs[config.active_env].client_secret);
        const basicAuth = base64.encode(`${clientId}:${clientSecret}`);

        try {
            const response = await fetch(tokenEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${basicAuth}`,
                },
                body: `grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}`,
            });

            if (response.ok) {
                const data = await response.json();
                const expiresAt = new Date().getTime() + data.expires_in * 1000;
                const token = {
                    accessToken: data.access_token,
                    expiresAt,
                    tokenType: data.token_type,
                    idToken: data.id_token,
                }
                setToken(token);
                sessionStorage.setItem('authToken', JSON.stringify(token));
                
                //if (data.access_token) {
                    const decoded_jwt = decodeJWT(data.access_token);
                    //if (decoded_jwt && decoded_jwt.groups) {
                        setTenants(decoded_jwt.groups);
                        sessionStorage.setItem('userTenants', JSON.stringify(decoded_jwt.groups));
                    //}
                    /* setIsLoggedIn(true); */
                    setIsAuthorized(true);
                    navigate('/'); // Redirect to home on successful login
                //}

            } else {
                console.error('Error during token retrieval:', response.statusText);
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const isTokenValid = (providedToken = token) => {
        if (providedToken) {
            const now = new Date().getTime();
            return now < providedToken.expiresAt;
        }
        return false;
    };

    const logout = () => {
        setIsAuthorized(false);
        setIsLoggedIn(false);
        setToken(null); // Clear token state
        // Ensure data is removed from cache
        sessionStorage.removeItem('authToken'); 
        sessionStorage.removeItem('userTenants');
        sessionStorage.removeItem('currentTenant');
        sessionStorage.removeItem('userProjectPaths');
        navigate('/login', { replace: true });
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, isAuthorized, token, login, logout, setToken, handleOAuthCallback, isTokenValid, userTenants, setTenants, setCurrentTenant, currentTenant, setIsLoggedIn, setIsAuthorized }}>
            {children}
        </AuthContext.Provider>
    );
};
