import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { 
  Button, Breadcrumbs, Link, 
  Typography, IconButton, Tooltip, TextField, Box, InputAdornment, LinearProgress
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
/* import LinearProgressIndeterminate from './LinearProgressIndeterminate'; */
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ReportGridRowContextMenu from './ReportGridRowContextMenu';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useAppContext } from '../contexts/AppContext';
import { convertToDateString, convertToDate } from '../utilities/dateUtils';
import config from '../config';
import EditAcceptanceCriteria from './ACEdit';

const ListPage = ({ rows, onRowViewClick, onOptionSelect, onAddNewCriteria, onCriteriaLoading, searchQuery, onSearchChange, handleClearSearch }) => {

  const contextMenuOptions = [
    { id: 'edit', label: 'Anpassen', icon: <EditIcon /> },
    { id: 'duplicate', label: 'Duplizieren', icon: <FileCopyIcon /> },
    { id: 'delete', label: 'Löschen', icon: <DeleteForeverIcon />, color: 'red' },
  ];

  const columns = [
    { field: 'name', headerName: 'Titel', flex: 0.5 },
    { field: 'description', headerName: 'Beschreibung', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <ReportGridRowContextMenu
          options={contextMenuOptions} 
          onMenuItemClick={(option) => {
            onRowViewClick(row);
            onOptionSelect(option.id, row);
          }} />
        </>
      ),
    }
  ];

  // Using row index as a unique identifier
  const getRowId = React.useCallback((row, index) => `${row.name}-${index}`, []);

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === 'string' &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    ) || (row.project && row.project.name.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const CustomGrid = () => {
   /*  if (onCriteriaoading) {
      return (
        <div style={{ width: '100%' }}>
          <LinearProgressIndeterminate isLoading={onCriteriaoading} />
        </div>)
    } else { */
      return (
        <div style={{ width: '100%' }}>
          <DataGridPro
          getRowId={getRowId}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
            }}
            pagination
            pageSizeOptions={config.ui_settings.pagination_page_size_options}
            rows={filteredRows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            checkboxSelection
            disableRowSelectionOnClick
            disableMultipleSelection
            sx={{

              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'lightgrey',
              },
            }}
          />
        </div>
      );
    /* } */
  }

  return (
    <div style={{ width: '100%' }}>

        {onCriteriaLoading ? (
          <LinearProgress />
        ) : (

          <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 1 }}>
              <TextField
                label="Suchen"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => onSearchChange(e.target.value)}
                sx={{ width: '25%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClearSearch}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box>
                <Tooltip title="Auswertungen ermöglichen die Anwendung von Akzeptanzkriterien auf Dokumente.">
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onAddNewCriteria}
                      disabled={onCriteriaLoading}
                    >
                      Neue Akzeptanzkriterium
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
            <CustomGrid />
          </React.Fragment>
      )}

    </div>
  );
};

const DetailPage = ({ row, onBackClick, onSaveClick, action }) => {
  const [editData, setEditData] = useState({
    ...row,
    start_date: convertToDate(row.start_date),
    end_date: convertToDate(row.end_date),
  });

  const handleEditChange = (field, value) => {
    setEditData(prevState => ({ ...prevState, [field]: value }));
  };

  const handleLocalSaveClick = (updatedEditData) => {
    onSaveClick(updatedEditData);
  };

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
          <IconButton onClick={onBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
            <Link color="inherit" href="#" onClick={onBackClick}>
              Liste
            </Link>
            <Typography color="textPrimary" style={{ fontWeight: 'bold', fontSize: '1.45rem' }}>{row.name}</Typography>
          </Breadcrumbs>
          <Tooltip title="Erstellen Sie ein Projekt um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden.">
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
        <EditAcceptanceCriteria editData={editData} handleEditChange={handleEditChange} action={action} handleLocalSaveClick={handleLocalSaveClick} onBackClick={onBackClick} />
      </div>
    );
};

export default function AcceptanceCriteria() {
  const { userData, userProjects, getAcceptanceCriteria, createAcceptanceCriteria, editAcceptanceCriteria, removeAcceptanceCriteria, } = useAppContext();
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [criteria, setCriteria] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadAcceptanceCriteria = async () => {
    setIsLoading(true);
    try {
      const fetchedCriteria = await getAcceptanceCriteria();
      if (fetchedCriteria) {
        setCriteria(fetchedCriteria);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch report templates', error);
    }
  };

  useEffect( () => {
    loadAcceptanceCriteria();
  }, []);

  const handleAddNewCriteria = () => {
    setSelectedRow({
      name: '',
      description: '',
      prompt: '',
      return_schema: {
        requirements: [
          {
            name:"", 
            description:""
          }
        ]
      },
    });
    setAction('create');
  };

  const handleDuplicateRow = (row) => {
    setSelectedRow({
      ...row,
      name: `${row.name} - Copy`
    });
    setAction('create');
  };

  const handleRowViewClick = (row) => {
    setSelectedRow(row);
    console.log('selected row: ', row.id);
  };

  const handleOptionSelect = (optionId, row) => {
    setAction(optionId);
    setSelectedRow(row);
    if (optionId === 'delete') {
      setDeleteModalOpen(true);
      console.log('selected row Delete: ', row);
      setDeleteRow(row);
    } else if (optionId === 'duplicate') {
      handleDuplicateRow(row);
    } else {
      console.log('selected row: ', row, ' for option ', optionId);
    }
  };

  const handleBackClick = () => {
    setSelectedRow(null);
    setAction(null);
  };

  const handleSaveClick = (editData) => {
    if (action === 'edit') {
      editAcceptanceCriteria(editData)
        .then(() => {
          loadAcceptanceCriteria();
        })
        .catch(error => {
          console.error('Failed to update report', error);
        });
    } else {
      createAcceptanceCriteria(editData)
        .then(() => {
          loadAcceptanceCriteria();
        })
        .catch(error => {
          console.error('Failed to add report', error);
        });
    }

    setAction(null);
    setSelectedRow(null);
  };

  const handleConfirmDelete = (row) => {
    if (row) {
      removeAcceptanceCriteria(row.name).then(() => {
        loadAcceptanceCriteria();
      });
    }
    setDeleteModalOpen(false);
    setAction(null);
    setSelectedRow(null);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div>
      {selectedRow && action !== 'delete' ? (
        <DetailPage
          userData={userData}
          userProjects={userProjects}
          row={selectedRow}
          onBackClick={handleBackClick}
          onSaveClick={handleSaveClick}
          action={action}
        />
      ) : (
        <ListPage
          rows={criteria}
          onRowViewClick={handleRowViewClick}
          onOptionSelect={(optionId, row) => handleOptionSelect(optionId, row)}
          onAddNewCriteria={handleAddNewCriteria}
          onCriteriaLoading={isLoading}
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          handleClearSearch={handleClearSearch}
        />
      )}
      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
        row={deleteRow}
      />
    </div>
  );
}